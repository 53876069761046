import React, { createContext, useContext, useState, useEffect } from 'react';
import { ASSISTANT_PRESETS } from '../config/assistantPresets';
import { MODEL_URL, ENDPOINTS, getModelUrl } from '../config/apiConfig';
import { useAuth } from './AuthContext';

const AssistantContext = createContext();

export function AssistantProvider({ children }) {
  const [currentProfile, setCurrentProfile] = useState({
    identity: {
      name: '',
      description: 'A helpful AI assistant who is still under development but ready to help the user with anything.'
    },
    purpose: '',
    basePersonality: '',
    addOnTraits: [],
    customPrompts: {
      purpose: '',
      basePersonality: '',
      addOnTraits: ''
    }
  });
  const [isLoading, setIsLoading] = useState(true);
  const [systemPrompts, setSystemPrompts] = useState(null);
  const { getAuthenticatedAxios } = useAuth();

  // 构建完整的配置信息
  const buildFullProfile = (profile) => {
    const fullProfile = {
        identity: {
        name: profile.identity.name,
        description: profile.identity.description || 'A helpful AI assistant who is still under development but ready to help the user with anything.'
        },
        purpose: {
          id: profile.purpose,
          prompts: profile.customPrompts.purpose 
            ? [profile.customPrompts.purpose]
          : ASSISTANT_PRESETS.purposes[profile.purpose]?.prompt.split('\n').filter(line => line.trim())
        },
        basePersonality: {
          id: profile.basePersonality,
          prompt: profile.customPrompts.basePersonality 
          || ASSISTANT_PRESETS.basePersonalities[profile.basePersonality]?.prompt
        },
        addOnTraits: profile.addOnTraits.map(trait => ({
          id: trait,
        prompt: ASSISTANT_PRESETS.addOnTraits[trait]?.prompt
        })),
      customPrompts: profile.customPrompts,
      system_prompts: systemPrompts
    };

    return fullProfile;
  };

  // 更新配置
  const updateAssistantProfile = async (newProfile) => {
    try {
      if (!newProfile) {
        console.error('No profile provided to update');
        return false;
      }

      const authAxios = await getAuthenticatedAxios();
      
      // 先获取当前服务器配置
      const currentResponse = await authAxios.get(getModelUrl(ENDPOINTS.ASSISTANT_PROFILE));
      const currentData = currentResponse.data;
      
      // 构建新的完整配置，保留原有的system_prompts
      const fullProfile = {
        ...buildFullProfile(newProfile),
        system_prompts: currentData.profile?.system_prompts || systemPrompts
      };

      console.log('Sending full profile:', fullProfile);

      const response = await authAxios.post(
        getModelUrl(ENDPOINTS.ASSISTANT_PROFILE),
        { profile: fullProfile }
      );
      
      if (response.status === 200) {
        setCurrentProfile(newProfile);
        return true;
      }

      console.error('Failed to update profile:', response.data);
      return false;
    } catch (error) {
      console.error('Failed to save assistant profile:', error);
      return false;
    }
  };

  // 加载配置时转换回简化版本
  useEffect(() => {
    const loadProfile = async () => {
      try {
        setIsLoading(true);
        const authAxios = await getAuthenticatedAxios();
        const response = await authAxios.get(getModelUrl(ENDPOINTS.ASSISTANT_PROFILE));
        if (response.status === 200) {
          const data = response.data;
          setSystemPrompts(data.profile?.system_prompts);
          
          const serverProfile = data.profile?.current_profile || {};
          
          const simpleProfile = {
            identity: {
              name: serverProfile.identity?.name || '',
              description: serverProfile.identity?.description || 'A helpful AI assistant who is still under development but ready to help the user with anything.'
            },
            purpose: serverProfile.purpose?.id || '',
            basePersonality: serverProfile.basePersonality?.id || '',
            addOnTraits: serverProfile.addOnTraits?.map(trait => trait.id) || [],
            customPrompts: {
              purpose: serverProfile.customPrompts?.purpose || '',
              basePersonality: serverProfile.customPrompts?.basePersonality || '',
              addOnTraits: serverProfile.customPrompts?.addOnTraits || ''
            }
          };
          
          setCurrentProfile(simpleProfile);
        }
      } catch (error) {
        console.error('Failed to load assistant profile:', error);
      } finally {
        setIsLoading(false);
      }
    };
    loadProfile();
  }, [getAuthenticatedAxios]);

  return (
    <AssistantContext.Provider value={{ 
      currentProfile,
      setCurrentProfile,
      updateAssistantProfile,
      isLoading,
      presets: ASSISTANT_PRESETS
    }}>
      {children}
    </AssistantContext.Provider>
  );
}

export const useAssistant = () => useContext(AssistantContext);