import React, { useState, useEffect } from 'react';
import { useTranslate } from '../../utils/translate';
import { useSettings } from '../../context/SettingsContext';

function AdvancedSettings() {
  const t = useTranslate();
  const { modelSettings, updateModelSettings, fetchSettings } = useSettings();
  const [selectedModel, setSelectedModel] = useState('');
  const [currentModelSettings, setCurrentModelSettings] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [saveMessage, setSaveMessage] = useState('');

  useEffect(() => {
    const initializeSettings = async () => {
      try {
        setIsLoading(true);
        await fetchSettings();
        if (modelSettings?.models?.length > 0) {
          setSelectedModel(modelSettings.selected_model || modelSettings.models[0].name);
        }
      } catch (error) {
        console.error('加载设置失败:', error);
      } finally {
        setIsLoading(false);
      }
    };

    initializeSettings();
  }, []);

  useEffect(() => {
    if (selectedModel && modelSettings.models) {
      const model = modelSettings.models.find(m => m.name === selectedModel);
      if (model) {
        setCurrentModelSettings(model.settings || {});
      }
    }
  }, [selectedModel, modelSettings]);

  const handleSettingChange = (key, value) => {
    console.log(`Changing setting ${key} to:`, value);
    setCurrentModelSettings(prev => {
      const updated = {
        ...prev,
        [key]: value
      };
      console.log('Updated model settings:', updated);
      return updated;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const currentModel = modelSettings.models.find(m => m.name === selectedModel);
      const updatedConfig = {
        ...modelSettings,
        selected_model: selectedModel,
        selected_model_id: currentModelSettings.MODEL_ID,
        models: modelSettings.models.map(model => {
          if (model.name === selectedModel) {
            return {
              ...model,
              settings: {
                ...model.settings,
                ...currentModelSettings
              }
            };
          }
          return model;
        })
      };

      await updateModelSettings(updatedConfig);
      setSaveMessage('设置保存成功！');
      await fetchSettings();
      
      setTimeout(() => setSaveMessage(''), 3000);
    } catch (error) {
      console.error('保存设置失败:', error);
      setSaveMessage('设置保存失败，请重试。');
      setTimeout(() => setSaveMessage(''), 3000);
    }
  };

  return (
    <div className="bg-bg p-4 rounded-lg shadow">
      <h3 className="text-xl font-bold mb-4 text-inherit">{t('advancedSettings.title')}</h3>
      {isLoading ? (
        <div className="text-major">加载中...</div>
      ) : (
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="flex flex-col">
            <label className="mb-2 font-medium text-major">
              {t('advancedSettings.selectModel')}:
              <select
                value={selectedModel}
                onChange={(e) => setSelectedModel(e.target.value)}
                className="mt-1 block w-full rounded-md border-outline bg-major text-major"
              >
                {!modelSettings?.models?.length ? (
                  <option value="">没有可用的模型</option>
                ) : (
                  modelSettings.models.map(model => (
                    <option key={model.name} value={model.name}>
                      {model.name || '未命名模型'}
                    </option>
                  ))
                )}
              </select>
            </label>
          </div>
          {selectedModel && (
            <div className="space-y-4">
              {(() => {
                const currentModel = modelSettings.models?.find(m => m.name === selectedModel);
                const modelType = currentModel?.type?.toLowerCase();
                
                return (
                  <>
                    {currentModelSettings.available_models && (
                      <label className="flex flex-col mb-2 font-medium text-major">
                        {t('advancedSettings.modelId')}:
                        <select
                          value={currentModelSettings.MODEL_ID || ''}
                          onChange={(e) => {
                            console.log('Selected model ID:', e.target.value);
                            handleSettingChange('MODEL_ID', e.target.value);
                          }}
                          className="mt-1 block w-full rounded-md border-outline bg-major text-major"
                        >
                          {currentModelSettings.available_models.map((model) => (
                            <option key={model.id} value={model.id}>
                              {model.name}
                            </option>
                          ))}
                        </select>
                      </label>
                    )}

                    <label className="flex flex-col mb-2 font-medium text-major">
                      {t('advancedSettings.temperature')}:
                      <input
                        type="number"
                        step="0.1"
                        min="0"
                        max="1"
                        value={currentModelSettings.TEMPERATURE || 0.7}
                        onChange={(e) => handleSettingChange('TEMPERATURE', parseFloat(e.target.value))}
                        className="mt-1 block w-full rounded-md border-outline bg-major text-major"
                      />
                    </label>

                    <label className="flex flex-col mb-2 font-medium text-major">
                      Max New Tokens:
                      <input
                        type="number"
                        min="1"
                        value={currentModelSettings.MAX_NEW_TOKENS || 2048}
                        onChange={(e) => handleSettingChange('MAX_NEW_TOKENS', parseInt(e.target.value))}
                        className="mt-1 block w-full rounded-md border-outline bg-major text-major focus:border-secondary focus:ring focus:ring-secondary focus:ring-opacity-50"
                      />
                    </label>

                    <label className="flex flex-col mb-2 font-medium text-major">
                      Top P:
                      <input
                        type="number"
                        step="0.01"
                        min="0"
                        max="1"
                        value={currentModelSettings.TOP_P || 0.95}
                        onChange={(e) => handleSettingChange('TOP_P', parseFloat(e.target.value))}
                        className="mt-1 block w-full rounded-md border-outline bg-major text-major focus:border-secondary focus:ring focus:ring-secondary focus:ring-opacity-50"
                      />
                    </label>

                    <label className="flex flex-col mb-2 font-medium text-major">
                      Top K:
                      <input
                        type="number"
                        min="0"
                        value={currentModelSettings.TOP_K || 50}
                        onChange={(e) => handleSettingChange('TOP_K', parseInt(e.target.value))}
                        className="mt-1 block w-full rounded-md border-outline bg-major text-major focus:border-secondary focus:ring focus:ring-secondary focus:ring-opacity-50"
                      />
                    </label>

                    <label className="flex flex-col mb-2 font-medium text-major">
                      Max Context Tokens:
                      <input
                        type="number"
                        min="256"
                        max="8192"
                        value={currentModelSettings.MAX_CONTEXT_TOKENS || 4096}
                        onChange={(e) => handleSettingChange('MAX_CONTEXT_TOKENS', parseInt(e.target.value))}
                        className="mt-1 block w-full rounded-md border-outline bg-major text-major focus:border-secondary focus:ring focus:ring-secondary focus:ring-opacity-50"
                      />
                    </label>

                    {modelType === 'gpt' && (
                      <label className="flex flex-col mb-2 font-medium text-major">
                        {t('advancedSettings.openaiApiKey')}:
                        <input
                          type="text"
                          value={currentModelSettings.OPENAI_API_KEY || ''}
                          onChange={(e) => handleSettingChange('OPENAI_API_KEY', e.target.value)}
                          className="mt-1 block w-full rounded-md border-outline bg-major text-major"
                        />
                      </label>
                    )}

                    {modelType === 'nvidia_llama' && (
                      <>
                        <label className="flex flex-col mb-2 font-medium text-major">
                          {t('advancedSettings.nvidiaApiKey')}:
                          <input
                            type="text"
                            value={currentModelSettings.API_KEY || ''}
                            onChange={(e) => handleSettingChange('API_KEY', e.target.value)}
                            className="mt-1 block w-full rounded-md border-outline bg-major text-major"
                          />
                        </label>
                        <label className="flex flex-col mb-2 font-medium text-major">
                          {t('advancedSettings.nvidiaBaseUrl')}:
                          <input
                            type="text"
                            value={currentModelSettings.BASE_URL || ''}
                            onChange={(e) => handleSettingChange('BASE_URL', e.target.value)}
                            className="mt-1 block w-full rounded-md border-outline bg-major text-major"
                          />
                        </label>
                      </>
                    )}

                    {modelType === 'anthropic' && (
                      <label className="flex flex-col mb-2 font-medium text-major">
                        {t('advancedSettings.claudeApiKey')}:
                        <input
                          type="text"
                          value={currentModelSettings.API_KEY || ''}
                          onChange={(e) => handleSettingChange('API_KEY', e.target.value)}
                          className="mt-1 block w-full rounded-md border-outline bg-major text-major"
                        />
                      </label>
                    )}
                  </>
                );
              })()}
            </div>
          )}
          <button 
            type="submit" 
            className="mt-4 px-4 py-2 bg-button-secondary rounded text-secondary hover:bg-highlight transition-colors duration-300 ease-in-out"
          >
            {t('advancedSettings.saveSettings')}
          </button>
        </form>
      )}
      {saveMessage && <div className="mt-4 p-2 bg-highlight text-inherit rounded">{saveMessage}</div>}
    </div>
  );
}

export default AdvancedSettings;
