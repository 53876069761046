import { translations } from '../translations';
import { usePreference } from '../context/PreferenceContext';

export const useTranslate = () => {
  const { language } = usePreference();

  const t = (key) => {
    const keys = key.split('.');
    let result = translations[language];
    for (let k of keys) {
      if (result[k] === undefined) {
        console.warn(`Translation key "${key}" not found for language "${language}"`);
        return key;
      }
      result = result[k];
    }
    return result;
  };

  return t;
};