import React, { useState } from 'react';
import ChatBox from '../components/chatBox';
import CalendarWidget from '../components/calendar/calendarWidget';
import PlaceholderWidget from '../components/PlaceholderWidget';
import { useTranslate } from '../utils/translate';

const HomePage = () => {
  const t = useTranslate();
  const [activeTab, setActiveTab] = useState('chat'); // 'chat' or 'calendar'

  return (
    <div className="flex flex-col h-screen bg-background dark:bg-background-dark">
      {/* 移动端标签栏 */}
      <div className="sm:hidden flex border-b border-gray-200 dark:border-gray-700">
        <button
          className={`flex-1 py-3 text-sm font-medium ${
            activeTab === 'chat'
              ? 'text-highlight border-b-2 border-highlight'
              : 'text-gray-500'
          }`}
          onClick={() => setActiveTab('chat')}
        >
          {t('homePage.chatTab')}
        </button>
        <button
          className={`flex-1 py-3 text-sm font-medium ${
            activeTab === 'calendar'
              ? 'text-highlight border-b-2 border-highlight'
              : 'text-gray-500'
          }`}
          onClick={() => setActiveTab('calendar')}
        >
          {t('homePage.calendarTab')}
        </button>
      </div>

      {/* 内容区域 */}
      <div className="flex-1 overflow-hidden flex flex-col sm:flex-row">
        {/* 桌面端左侧组件 */}
        <div className="hidden sm:flex sm:flex-col sm:w-1/2 space-y-4 p-4">
          <CalendarWidget />
          <PlaceholderWidget title={t('homePage.todoWidget')} />
        </div>

        {/* 主体内容区域 */}
        <div className="flex-1 p-4">
          {/* 移动端根据选项显示的组件 */}
          {activeTab === 'calendar' && (
            <div className="sm:hidden space-y-4">
              <CalendarWidget />
              <PlaceholderWidget title={t('homePage.todoWidget')} />
            </div>
          )}
          {/* ChatBox 组件，始终只渲染一次 */}
          <div
            className={`bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden ${
              activeTab !== 'chat' && 'hidden sm:block'
            } ${
              // 根据设备使用不同的高度
              activeTab === 'chat'
                ? 'h-[calc(100vh-120px)]'  // 移动端高度
                : 'h-[80vh]'               // 桌面端高度
            }`}
          >
            <ChatBox />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
