/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import InputField from './chat/InputField';
import MessageBubble from './chat/MessageBubble';
import { useTranslate } from '../utils/translate';
import { useChat } from '../context/ChatContext';
import { WS_URL, API_URL, ENDPOINTS, getApiUrl } from '../config/apiConfig';
import { useAuth } from '../context/AuthContext';

const ChatBox = () => {
  const { messages, setMessages, addMessage, updateLastMessage, isStreaming, setIsStreaming, fetchChatHistory } = useChat();
  const [inputMessage, setInputMessage] = useState('');
  const [isDebugMode, setIsDebugMode] = useState(true);
  const [isConnected, setIsConnected] = useState(false);
  const { getAuthenticatedAxios, getAccessTokenSilently } = useAuth();

  const wsRef = useRef(null);
  const chatContainerRef = useRef(null);
  const lastBlockIdRef = useRef(-1);
  const t = useTranslate();

  useEffect(() => {
    const connectWebSocket = async () => {
      try {
        // 获取认证 token
        const token = await getAccessTokenSilently({
          audience: 'https://chat-api',
          scope: 'read:messages write:messages'
        });

        // 确保 token 存在
        if (!token) {
          throw new Error('无法获取认证 token');
        }

        // 修改 WebSocket 连接方式，确保正确设置协议
        const socket = new WebSocket(
          WS_URL,
          ['Bearer', token] // 这里必须是数组形式
        );

        wsRef.current = socket;

        socket.onopen = () => {
          console.log('WebSocket 连接已建立');
          setIsConnected(true);
        };

        socket.onclose = (event) => {
          console.log('WebSocket 连接已关闭:', event.code, event.reason);
          setIsConnected(false);
        };

        socket.onerror = (error) => {
          console.error('WebSocket 连接错误:', error);
          setIsConnected(false);
        };

        socket.onmessage = handleWebSocketMessage;
      } catch (error) {
        console.error('WebSocket 连接失败:', error);
        setIsConnected(false);
      }
    };

    // 初始化连接和获取历史记录
    connectWebSocket();
    fetchChatHistory();

    // 清理函数
    return () => {
      if (wsRef.current?.readyState === WebSocket.OPEN) {
        wsRef.current.close();
      }
    };
  }, []);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);
  
  const handleWebSocketMessage = useCallback((event) => {
    const data = JSON.parse(event.data);
    if (data.type === 'ai_response') {
      console.log(`[DEBUG] 收到块 ID: ${data.blockId}, 当前最后处理的块 ID: ${lastBlockIdRef.current}`);
  
      if (data.blockId <= lastBlockIdRef.current) {
        console.log(`[DEBUG] 忽略已处理的块 ID: ${data.blockId}`);
        return; // 忽略已处理的块
      }

      // 更新 lastBlockIdRef.current 在 setMessages 调用之前
      lastBlockIdRef.current = data.blockId;
      console.log(`[DEBUG] 更新最后处理的块 ID 为: ${data.blockId}`);

      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        const lastMessage = updatedMessages[updatedMessages.length - 1];
        if (lastMessage && lastMessage.sender === 'ai') {
          if (lastMessage.status === 'loading') {
            // 第一个响应块，替换加载状态
            return [
              ...updatedMessages.slice(0, -1),
              {
                text: data.content,
                sender: 'ai',
                status: 'streaming'
              }
            ];
          } else if (lastMessage.status === 'streaming') {
            // 追加内容到现有消息
            return [
              ...updatedMessages.slice(0, -1),
              {
                ...lastMessage,
                text: lastMessage.text + data.content
              }
            ];
          }
        }
        return updatedMessages;
      });
  
    } else if (data.type === 'ai_response_end') {
      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        const lastMessage = updatedMessages[updatedMessages.length - 1];
        if (lastMessage && lastMessage.sender === 'ai') {
          lastMessage.status = 'complete';
        }
        return updatedMessages;
      });
      setIsStreaming(false);
      console.log(`[DEBUG] 重置最后处理的块 ID`);
      lastBlockIdRef.current = -1; // 重置块ID
    }
  }, []);
  

  const handleSendMessage = async () => {
    if (inputMessage.trim() === '' || isStreaming) return;

    const userMessage = { text: inputMessage, sender: 'user', status: 'sent' };
    const loadingMessage = { text: '', sender: 'ai', status: 'loading' };
    
    setMessages(prev => [...prev, userMessage, loadingMessage]);
    setInputMessage('');
    setIsStreaming(true);

    try {
      const authAxios = await getAuthenticatedAxios('write:messages');
      await authAxios.post(
        getApiUrl(ENDPOINTS.CHAT), 
        { message: inputMessage }
      );
    } catch (error) {
      console.error('发送消息时出错:', error);
      console.log('错误详情:', {
        name: error.name,
        message: error.message,
        response: error.response?.data
      });
      
      setMessages(prev => {
        const updated = [...prev];
        const lastMessage = updated[updated.length - 1];
        if (lastMessage.sender === 'ai') {
          lastMessage.status = 'error';
          lastMessage.text = t('message.connectionError');
        }
        return updated;
      });
      setIsStreaming(false);
    }
  };

  return (
    <div className="layout-container">
      <div className="layout-header">
        <span className="text-inherit">{t('chatBox.title')}</span>
       
      </div>

      <div ref={chatContainerRef} className="layout-content">
        {messages.map((message, index) => (
          <MessageBubble key={index} message={message} />
        ))}
      </div>

      <div className="layout-footer">
        <InputField
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          onSend={handleSendMessage}
          isStreaming={isStreaming}
        />
      </div>
    </div>
  );
};

export default ChatBox;
