import React, { useEffect, useRef } from 'react';
import { useSettings } from '../../context/SettingsContext';
import { useAuth0 } from '@auth0/auth0-react';
import ThemeToggle from './ThemeToggle';
import { useTranslate } from '../../utils/translate';

function SettingsPopup({ onClose, onOpenSettings }) {
  const { settings } = useSettings();
  const { logout } = useAuth0();
  const popupRef = useRef(null);
  const t = useTranslate();

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const handleOpenSettings = (tab) => {
    onOpenSettings(tab);
  };

  return (
    <div className="fixed inset-0 z-50 flex pt-14 sm:pt-0 sm:absolute">
      {/* 背景遮罩 */}
      <div className="absolute inset-0 bg-black/50 sm:hidden" onClick={onClose} />
      
      {/* 弹窗内容 */}
      <div 
        ref={popupRef} 
        className="relative w-full sm:w-56 
                   sm:absolute sm:top-full sm:right-0 
                   bg-app
                   rounded-b-lg sm:rounded-lg 
                   p-4 
                   sm:mt-2
                   mx-auto sm:mx-0
                   max-w-md
                   max-h-[calc(100vh-42rem)]
                   shadow-lg
                   overflow-y-auto"
      >
        <button 
          className="w-full mb-2 px-4 py-2 text-sm rounded bg-button-secondary" 
          onClick={() => handleOpenSettings('personal')}
        >
          {t('settingsPopup.personalSettings')}
        </button>
        <button 
          className="w-full mb-2 px-4 py-2 text-sm rounded bg-button-secondary" 
          onClick={() => handleOpenSettings('preferences')}
        >
          {t('settingsPopup.preferencesSettings')}
        </button>
        <button 
          className="w-full mb-2 px-4 py-2 text-sm rounded bg-button-secondary" 
          onClick={() => handleOpenSettings('advanced')}
        >
          {t('settingsPopup.advancedSettings')}
        </button>
        <button 
          className="w-full mb-2 px-4 py-2 text-sm rounded bg-button-secondary" 
          onClick={() => handleOpenSettings('assistant')}
        >
          {t('settingsPopup.assistantSettings')}
        </button>
        <div className="border-t border-outline my-4" />
        <button 
          className="w-full mb-4 px-4 py-2 text-sm rounded bg-button-secondary hover:bg-button-secondary/80 transition-colors" 
          onClick={() => logout({ returnTo: window.location.origin })}
        >
          {t('auth.logout')}
        </button>
        <div className="flex items-center justify-between">
          <span className="text-inherit">
            {settings.isDarkMode ? t('settingsPopup.darkMode') : t('settingsPopup.lightMode')}
          </span>
          <ThemeToggle />
        </div>
      </div>
    </div>
  );
}

export default SettingsPopup;
