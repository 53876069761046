import React from 'react';
import { useTranslate } from '../utils/translate';

const ConfirmDialog = ({ isOpen, onClose, onConfirm, message }) => {
  const t = useTranslate();

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-major text-major p-6 rounded-lg shadow-xl">
        <p className="mb-4">{message}</p>
        <div className="flex justify-end space-x-2">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-secondary text-secondary rounded hover:bg-highlight hover:text-highlight transition duration-300 ease-in-out"
          >
            {t('common.cancel')}
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-highlight text-highlight rounded hover:bg-red-500 hover:text-white transition duration-300 ease-in-out"
          >
            {t('common.confirm')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDialog;
