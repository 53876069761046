import React, { useState, useRef, useEffect } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { useTranslate } from '../utils/translate';

const ImageCropDialog = ({ isOpen, onClose, onConfirm, image }) => {
  const t = useTranslate();
  const [crop, setCrop] = useState({
    unit: '%',
    width: 100,
    height: 100,
    x: 0,
    y: 0,
    aspect: 1
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const imgRef = useRef(null);

  useEffect(() => {
    console.log('ImageCropDialog 状态更新:', {
      hasImage: !!image,
      imageLength: image?.length,
      hasImgRef: !!imgRef.current,
      completedCrop
    });
  }, [image, completedCrop]);

  const handleImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    const initialCrop = centerAspectCrop(width, height, 1);
    setCrop(initialCrop);
    setCompletedCrop(initialCrop);
    console.log('图片加载完成，设置初始裁剪区域:', initialCrop);
  };

  const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
    const size = Math.min(mediaWidth, mediaHeight);
    return {
      unit: '%',
      width: (size / mediaWidth) * 100,
      height: (size / mediaHeight) * 100,
      x: ((mediaWidth - size) / 2 / mediaWidth) * 100,
      y: ((mediaHeight - size) / 2 / mediaHeight) * 100,
    };
  };

  const getCroppedImage = () => {
    if (!completedCrop || !imgRef.current) return null;

    const canvas = document.createElement('canvas');
    const image = imgRef.current;
    
    // 限制最大尺寸
    const MAX_SIZE = 800;
    let width = completedCrop.width;
    let height = completedCrop.height;
    
    if (width > MAX_SIZE || height > MAX_SIZE) {
      const ratio = Math.min(MAX_SIZE / width, MAX_SIZE / height);
      width *= ratio;
      height *= ratio;
    }

    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      completedCrop.x,
      completedCrop.y,
      completedCrop.width,
      completedCrop.height,
      0,
      0,
      width,
      height
    );

    // 使用较低的质量来减小文件大小
    return canvas.toDataURL('image/jpeg', 0.8);
  };

  const handleConfirm = () => {
    const croppedImage = getCroppedImage();
    if (croppedImage) {
      console.log('确认裁剪，准备返回图片数据');
      onConfirm(croppedImage);
    } else {
      console.error('裁剪失败，无法返回图片数据');
    }
  };

  return isOpen ? (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-major text-major p-6 rounded-lg shadow-xl">
        <ReactCrop
          src={image}
          crop={crop}
          onChange={(c) => {
            console.log('裁剪区域变化:', c);
            setCrop(c);
          }}
          onComplete={(c) => {
            console.log('完成裁剪:', c);
            setCompletedCrop(c);
          }}
          aspect={1}
          circularCrop
        >
          <img 
            ref={imgRef}
            src={image} 
            onLoad={handleImageLoad} 
            alt="Crop preview" 
            style={{ maxWidth: '100%' }}
          />
        </ReactCrop>
        <div className="flex justify-end space-x-2 mt-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-secondary text-secondary rounded hover:bg-highlight hover:text-highlight transition duration-300 ease-in-out"
          >
            {t('common.cancel')}
          </button>
          <button
            onClick={handleConfirm}
            disabled={!completedCrop?.width || !completedCrop?.height}
            className="px-4 py-2 bg-highlight text-highlight rounded hover:bg-green-500 hover:text-white transition duration-300 ease-in-out disabled:opacity-50"
          >
            {t('common.confirm')}
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default ImageCropDialog;
