import React, { useState, useEffect } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { userConfig } from '../../config/userConfig';
import { useTranslate } from '../../utils/translate';
import { usePersonal } from '../../context/PersonalContext';
import timezones from '../../config/timezones';

function PersonalSettings() {
  const t = useTranslate();
  const { currentProfile, updatePersonalProfile, isLoading } = usePersonal();
  
  // 模型相关的用户信息状态
  const [profile, setProfile] = useState(currentProfile);

  // 加载现有设置
  useEffect(() => {
    if (!isLoading) {
      setProfile(currentProfile);
      // 同步用户名到localStorage
      if (currentProfile.name) {
        localStorage.setItem('userName', currentProfile.name);
      }
    }
  }, [currentProfile, isLoading]);

  // 用户名变更处理
  const handleNameChange = (value) => {
    setProfile(prev => ({
      ...prev,
      name: value
    }));
    localStorage.setItem('userName', value);
  };

  // 新增模型用户信息相关的处理函数
  const handleProfileChange = (field, value) => {
    setProfile(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleTimeAwarenessChange = (field, value) => {
    setProfile(prev => ({
      ...prev,
      time_awareness: {
        ...prev.time_awareness,
        [field]: value
      }
    }));
  };

  const handleScheduleChange = (field, value) => {
    setProfile(prev => ({
      ...prev,
      time_awareness: {
        ...prev.time_awareness,
        schedule: {
          ...prev.time_awareness.schedule,
          [field]: value
        }
      }
    }));
  };

  // 统一的保存函数
  const handleSave = async () => {
    // 保存模型用户信息
    const success = await updatePersonalProfile(profile);
    
    if (success) {
      window.location.reload();
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-major text-major p-4 rounded-lg max-h-[80vh] overflow-y-auto">
      <h2 className="text-xl font-bold mb-4 sticky top-0 bg-major z-10 py-2">
        {t('personalSettings.title')}
      </h2>
      
      {/* 模型用户信息设置部分 */}
      <div className="mb-8">
        <div className="mb-4">
          <label className="block mb-2">{t('personalSettings.username')}：</label>
          <input
            type="text"
            value={profile.name || ''}
            onChange={(e) => handleNameChange(e.target.value)}
            className="w-full p-2 border border-outline rounded bg-bg text-bg"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2">{t('personalSettings.occupation')}：</label>
          <input
            type="text"
            value={profile.occupation || ''}
            onChange={(e) => handleProfileChange('occupation', e.target.value)}
            className="w-full p-2 border border-outline rounded bg-bg text-bg"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2">{t('personalSettings.backgroundInfo')}：</label>
          <textarea
            value={profile.background_info || ''}
            onChange={(e) => handleProfileChange('background_info', e.target.value)}
            className="w-full p-2 border border-outline rounded bg-bg text-bg"
            rows={4}
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2">{t('personalSettings.interests')}：</label>
          <div className="flex flex-wrap gap-2">
            {(profile.interests || []).map((interest, index) => (
              <div key={index} className="flex items-center bg-bg text-bg p-2 rounded">
                <span>{interest}</span>
                <button
                  onClick={() => {
                    const newInterests = profile.interests.filter((_, i) => i !== index);
                    handleProfileChange('interests', newInterests);
                  }}
                  className="ml-2 text-red-500"
                >
                  ×
                </button>
              </div>
            ))}
          </div>
          <div className="flex gap-2">
            <input
              type="text"
              placeholder={t('personalSettings.addInterest')}
              id="interestInput"
              onKeyDown={(e) => {
                if (e.key === 'Enter' && e.target.value.trim()) {
                  const newInterests = [...(profile.interests || []), e.target.value.trim()];
                  handleProfileChange('interests', newInterests);
                  e.target.value = '';
                }
              }}
              className="mt-2 w-full p-2 border border-outline rounded bg-bg text-bg"
            />
            <button
              onClick={() => {
                const input = document.getElementById('interestInput');
                if (input && input.value.trim()) {
                  const newInterests = [...(profile.interests || []), input.value.trim()];
                  handleProfileChange('interests', newInterests);
                  input.value = '';
                }
              }}
              className="mt-2 px-4 py-2 bg-button-secondary text-secondary rounded hover:bg-highlight transition-colors"
            >
              {t('personalSettings.add')}
            </button>
          </div>
        </div>

        {/* 时间设置部分 */}
        <div className="mb-4">
          <label className="block mb-2">{t('personalSettings.timeAwareness')}：</label>
          <div className="ml-4">
            <div className="mb-2">
              <input
                type="checkbox"
                checked={profile.time_awareness.enabled}
                onChange={(e) => handleTimeAwarenessChange('enabled', e.target.checked)}
                className="mr-2"
              />
              <span>{t('personalSettings.enableTimeAwareness')}</span>
            </div>

            {profile.time_awareness.enabled && (
              <div className="space-y-4">
                {/* 时区选择 */}
                <div className="mb-2">
                  <label className="block mb-1">{t('personalSettings.timezone')}：</label>
                  <select
                    value={profile.time_awareness.schedule.timezone}
                    onChange={(e) => handleScheduleChange('timezone', e.target.value)}
                    className="w-full p-2 border border-outline rounded bg-bg text-bg"
                  >
                    {timezones.map(tz => (
                      <option key={tz.value} value={tz.value}>
                        {tz.label}
                      </option>
                    ))}
                  </select>
                </div>

                {/* 工作日选择 */}
                <div className="mb-2">
                  <label className="block mb-1">{t('personalSettings.workDays')}：</label>
                  <div className="flex flex-wrap gap-2">
                    {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(day => (
                      <label key={day} className="flex items-center">
                        <input
                          type="checkbox"
                          checked={profile.time_awareness.schedule.work_days.includes(day)}
                          onChange={(e) => {
                            const newWorkDays = e.target.checked
                              ? [...profile.time_awareness.schedule.work_days, day]
                              : profile.time_awareness.schedule.work_days.filter(d => d !== day);
                            handleScheduleChange('work_days', newWorkDays);
                          }}
                          className="mr-1"
                        />
                        {t(`personalSettings.days.${day.toLowerCase()}`)}
                      </label>
                    ))}
                  </div>
                </div>

                {/* 基本时间设置 - 使用更友好的时间选择器 */}
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block mb-1">{t('personalSettings.workHours')}：</label>
                    <div className="flex items-center space-x-2">
                      <input
                        type="time"
                        value={profile.time_awareness.schedule.work_hours.split('-')[0]}
                        onChange={(e) => {
                          const [_, endTime] = profile.time_awareness.schedule.work_hours.split('-');
                          handleScheduleChange('work_hours', `${e.target.value}-${endTime}`);
                        }}
                        className="p-2 border border-outline rounded bg-bg text-bg"
                      />
                      <span>-</span>
                      <input
                        type="time"
                        value={profile.time_awareness.schedule.work_hours.split('-')[1]}
                        onChange={(e) => {
                          const [startTime] = profile.time_awareness.schedule.work_hours.split('-');
                          handleScheduleChange('work_hours', `${startTime}-${e.target.value}`);
                        }}
                        className="p-2 border border-outline rounded bg-bg text-bg"
                      />
                    </div>
                  </div>

                  <div>
                    <label className="block mb-1">{t('personalSettings.sleepTime')}：</label>
                    <div className="flex items-center space-x-2">
                      <input
                        type="time"
                        value={profile.time_awareness.schedule.sleep_time.split('-')[0]}
                        onChange={(e) => {
                          const [_, endTime] = profile.time_awareness.schedule.sleep_time.split('-');
                          handleScheduleChange('sleep_time', `${e.target.value}-${endTime}`);
                        }}
                        className="p-2 border border-outline rounded bg-bg text-bg"
                      />
                      <span>-</span>
                      <input
                        type="time"
                        value={profile.time_awareness.schedule.sleep_time.split('-')[1]}
                        onChange={(e) => {
                          const [startTime] = profile.time_awareness.schedule.sleep_time.split('-');
                          handleScheduleChange('sleep_time', `${startTime}-${e.target.value}`);
                        }}
                        className="p-2 border border-outline rounded bg-bg text-bg"
                      />
                    </div>
                  </div>
                </div>

                {/* 添加一个提示 */}
                <div className="mt-4 text-sm text-secondary italic">
                  {t('personalSettings.plannerNote')}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <button
        onClick={handleSave}
        className="bg-highlight text-highlight px-4 py-2 rounded sticky bottom-4 left-0 w-full"
      >
        {t('personalSettings.saveSettings')}
      </button>
    </div>
  );
}

export default PersonalSettings;
