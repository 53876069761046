import React, { useState } from 'react';
import { useTranslate } from '../../utils/translate';
import Modal from '../common/Modal';

function CustomPromptModal({ isOpen, onClose, currentProfile, setCurrentProfile, section }) {
  const t = useTranslate();
  const [prompts, setPrompts] = useState(
    section === 'global' 
      ? {
          purpose: currentProfile.customPrompts.purpose,
          basePersonality: currentProfile.customPrompts.basePersonality,
          addOnTraits: currentProfile.customPrompts.addOnTraits
        }
      : { [section]: currentProfile.customPrompts[section] }
  );

  const handleSave = () => {
    if (section === 'global') {
      setCurrentProfile(prev => ({
        ...prev,
        customPrompts: prompts
      }));
    } else {
      setCurrentProfile(prev => ({
        ...prev,
        customPrompts: {
          ...prev.customPrompts,
          [section]: prompts[section]
        }
      }));
    }
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="p-4">
        <h3 className="text-lg font-bold mb-4">
          {section === 'global' 
            ? t('assistantSettings.customPrompt.globalTitle')
            : t(`assistantSettings.customPrompt.${section}Title`)}
        </h3>
        
        {section === 'global' ? (
          // 全局自定义界面
          <>
            {Object.keys(prompts).map(key => (
              <div key={key} className="mb-4">
                <label className="block mb-2">{t(`assistantSettings.customPrompt.${key}Label`)}</label>
                <textarea
                  value={prompts[key]}
                  onChange={e => setPrompts(prev => ({...prev, [key]: e.target.value}))}
                  className="w-full p-2 rounded border border-outline bg-major text-major"
                />
              </div>
            ))}
          </>
        ) : (
          // 单个部分自定义界面
          <textarea
            value={prompts[section]}
            onChange={e => setPrompts({[section]: e.target.value})}
            className="w-full p-2 rounded border border-outline bg-major text-major"
          />
        )}
        
        <div className="flex justify-end mt-4">
          <button onClick={handleSave} className="px-4 py-2 bg-highlight text-highlight rounded">
            {t('common.save')}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default CustomPromptModal; 