import { createContext, useContext, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const { isAuthenticated, isLoading, loginWithRedirect, logout, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      console.log('Current URL:', window.location.href);
      console.log('Redirect URI:', `${window.location.origin}/`);
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  const getAuthenticatedAxios = async (scope = 'read:messages') => {
    try {
      const token = await getAccessTokenSilently({
        audience: 'https://chat-api',
        scope: scope
      });
      
      return axios.create({
        headers: { 
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
    } catch (error) {
      console.error('获取认证 token 失败:', error);
      throw error;
    }
  };

  const value = {
    isAuthenticated,
    isLoading,
    logout,
    getAccessTokenSilently,
    getAuthenticatedAxios
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuth = () => useContext(AuthContext);