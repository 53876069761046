import React from 'react';
import { useTranslate } from '../utils/translate';

const Daybreak = ({ onClick, className }) => {
  const t = useTranslate();

  return (
    <button
      onClick={onClick}
      className={`
        bg-highlight
        hover:bg-red-500
        text-highlight
        hover:text-white
        font-bold 
        py-2 
        px-4 
        rounded 
        transition 
        duration-300 
        ease-in-out
        ${className}
      `}
    >
      {t('daybreak.button')}
    </button>
  );
};

export default Daybreak;
