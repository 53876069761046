import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { userConfig } from '../../config/userConfig';
import { useTranslate } from '../../utils/translate';
import { usePreference } from '../../context/PreferenceContext';

const MessageBubble = ({ message }) => {
  const t = useTranslate();
  const { avatars } = usePreference();

  // 渲染加载动画或错误信息
  const renderLoadingOrError = () => {
    if (message.status === 'error') {
      return <span className="text-red-500">{t('message.error')}</span>;
    }
    
    if (message.status === 'loading') {
      return (
        <div className="flex items-center space-x-2">
          <div className="w-1.5 h-1.5 bg-current rounded-full animate-bounce delay-0"></div>
          <div className="w-1.5 h-1.5 bg-current rounded-full animate-bounce delay-100"></div>
          <div className="w-1.5 h-1.5 bg-current rounded-full animate-bounce delay-200"></div>
        </div>
      );
    }
    
    return (
      <ReactMarkdown 
        remarkPlugins={[remarkGfm]}
        components={{
          ol: ({children}) => (
            <ol className="list-decimal pl-4 my-0">{children}</ol>
          ),
          ul: ({children}) => (
            <ul className="list-disc pl-4 my-0">{children}</ul>
          ),
          p: ({children}) => (
            <p className="my-0 leading-normal whitespace-pre-line">{children}</p>
          ),
          em: ({children}) => (
            <em className="italic text-inherit">{children}</em>
          ),
          h1: ({children}) => (
            <h1 className="text-lg font-bold my-0 leading-normal">{children}</h1>
          ),
          h2: ({children}) => (
            <h2 className="text-base font-bold my-0 leading-normal">{children}</h2>
          ),
          h3: ({children}) => (
            <h3 className="text-base font-bold my-0 leading-normal">{children}</h3>
          ),
          h4: ({children}) => (
            <h4 className="text-base font-bold my-0 leading-normal">{children}</h4>
          )
        }}
        className="whitespace-pre-wrap break-words text-inherit leading-tight"
      >
        {message.text}
      </ReactMarkdown>
    );
  };

  return (
    <div
      className={`flex items-start space-x-2 ${
        message.sender === 'user' ? 'justify-end' : 'justify-start'
      }`}
    >
      {message.sender === 'ai' && (
        <img 
          src={avatars.ai || userConfig.defaultAIAvatar} 
          alt="AI" 
          className="w-8 h-8 rounded-full" 
        />
      )}
      <div className={`message-bubble ${message.sender === 'user' ? 'message-user' : 'message-ai'}`}>
        {renderLoadingOrError()}
      </div>
      {message.sender === 'user' && (
        <img 
          src={avatars.user || userConfig.defaultUserAvatar} 
          alt="User" 
          className="w-8 h-8 rounded-full" 
        />
      )}
    </div>
  );
};

export default MessageBubble;
