import React from 'react';

const TransitionOverlay = ({ isTransitioning }) => {
  return (
    <div 
      className={`
        fixed inset-0 bg-app z-50 transition-opacity duration-500 ease-in-out
        ${isTransitioning ? 'opacity-100' : 'opacity-0 pointer-events-none'}
      `}
    />
  );
};

export default TransitionOverlay;
