import React, { createContext, useContext, useState, useEffect } from 'react';
import { MODEL_URL, ENDPOINTS, getModelUrl } from '../config/apiConfig';
import { useAuth } from './AuthContext';

const PersonalContext = createContext();

export function PersonalProvider({ children }) {
  const [currentProfile, setCurrentProfile] = useState({
    user_id: '',
    name: '',
    occupation: '',
    interests: [],
    background_info: '',
    time_awareness: {
      enabled: true,
      schedule: {
        work_days: [],
        work_hours: '',
        sleep_time: '',
        breaks: {
          lunch: '',
          dinner: ''
        },
        timezone: '',
        special_dates: {}
      }
    }
  });
  const [isLoading, setIsLoading] = useState(true);
  const { getAuthenticatedAxios } = useAuth();

  // 更新配置
  const updatePersonalProfile = async (newProfile) => {
    try {
      const authAxios = await getAuthenticatedAxios();
      const response = await authAxios.post(
        getModelUrl(ENDPOINTS.PERSONAL_PROFILE),
        { profile: newProfile }
      );
      
      if (response.status === 200) {
        setCurrentProfile(newProfile);
        return true;
      }

      console.error('Failed to update profile:', response.data);
      return false;
    } catch (error) {
      console.error('Failed to save personal profile:', error);
      return false;
    }
  };

  // 加载配置
  useEffect(() => {
    const loadProfile = async () => {
      try {
        setIsLoading(true);
        const authAxios = await getAuthenticatedAxios();
        const response = await authAxios.get(getModelUrl(ENDPOINTS.PERSONAL_PROFILE));
        if (response.status === 200) {
          setCurrentProfile(response.data.profile || {});
        }
      } catch (error) {
        console.error('Failed to load personal profile:', error);
      } finally {
        setIsLoading(false);
      }
    };
    loadProfile();
  }, [getAuthenticatedAxios]);

  return (
    <PersonalContext.Provider value={{ 
      currentProfile,
      setCurrentProfile,
      updatePersonalProfile,
      isLoading
    }}>
      {children}
    </PersonalContext.Provider>
  );
}

export const usePersonal = () => useContext(PersonalContext); 