export const translations = {
  zh: {
    homePage: {
      title: '主页',
      chatWidget: '聊天窗口',
      calendarWidget: '日历',
      todoWidget: '待办事项',
      exampleButton: '示例按钮',
      chatTab: '聊天',
      calendarTab: '日历'
    },
    preferences: {
      title: '偏好设置',
      language: '语言',
      chinese: '中文',
      english: '英文',
      timeFormat: '时间格式',
      '12Hour': '12小时制',
      '24Hour': '24小时制',
      saveButton: '保存设置'
    },
    advancedSettings: {
      title: '高级设定',
      selectModel: '选择模型',
      temperature: '温度',
      openaiApiKey: 'OpenAI API 密钥',
      claudeApiKey: 'Claude API 密钥',
      nvidiaApiKey: 'Nvidia API 密钥',
      nvidiaBaseUrl: 'Nvidia 基础 URL',
      saveSettings: '保存设置',
      modelId: '模型版本'
    },
    personalSettings: {
      title: '个人设置',
      uiSettings: '界面设置',
      modelSettings: '模型设置',
      username: '用户名',
      userAvatar: '用户头像',
      userAvatarAlt: '用户头像',
      aiAvatar: 'AI头像',
      aiAvatarAlt: 'AI头像',
      occupation: '职业',
      interests: '兴趣爱好',
      backgroundInfo: '背景信息',
      timeAwareness: '时间感知',
      enableTimeAwareness: '启用时间感知',
      workDays: '工作日',
      workHours: '工作时间',
      sleepTime: '睡眠时间',
      breaks: '休息时间',
      lunch: '午餐时间',
      dinner: '晚餐时间',
      timezone: '时区',
      specialDates: '特殊日期',
      saveSettings: '保存设置',
      saving: '保存中...',
      saveSuccess: '保存成功',
      saveError: '保存失败',
      privacySettings: '隐私设定',
      workDays: '工作日',
      sleepTime: '睡眠时间',
      days: {
        monday: '周一',
        tuesday: '周二',
        wednesday: '周三',
        thursday: '周四',
        friday: '周五',
        saturday: '周六',
        sunday: '周日'
      },
      plannerNote: '更详细的日程安排功能即将推出...',
      addInterest: '添加新兴趣',
      add: '添加',
      removeInterest: '删除兴趣',
      interestPlaceholder: '输入新的兴趣爱好...'
    },
    settingsPage: {
      selectSettingsType: '请选择设置类型',
      personalSettings: '个人信息设置',
      preferencesSettings: '偏好设置',
      advancedSettings: '高级设定',
      assistantSettings: '助理设定',
      close: '关闭'
    },
    themeToggle: {
      switchToLightMode: '切换到日间模式',
      switchToDarkMode: '切换到夜间模式'
    },
    settingsPopup: {
      personalSettings: '个人信息设置',
      preferencesSettings: '偏好设置',
      advancedSettings: '高级设定',
      assistantSettings: '助理设定',
      darkMode: '夜间模式',
      lightMode: '日间模式'
    },
    inputField: {
      placeholder: '输入您的消息...',
      sendButton: '发送消息'
    },
    chatBox: {
      title: '聊天框',
      debugMode: '调试模式',
      websocketConnected: 'WebSocket 连接已建立',
      websocketClosed: 'WebSocket 连接已关闭',
      websocketError: 'WebSocket 错误:',
      sendMessageError: '发送消息错误:'
    },
    daybreak: {
      button: '结束一天',
      confirmMessage: '您确定要结束今天吗？这将生成今天的总结并开始新的一天。',
      processing: '处理中...'
    },
    common: {
      cancel: '取消',
      confirm: '确认',
      save: '保存',
      saving: '保存中...'
    },
    startPage: {
      aiAvatarAlt: 'AI头像',
      startButton: '开始一天'
    },
    assistantSettings: {
      title: '助理设定',
      identity: {
        title: 'AI助手名字',
        namePlaceholder: '请输入AI助手的名字（最多20个字符）',
        defaultName: 'Isaac',  // 默认名字
        description: '这是一个正在开发中，但随时准备帮助用户的AI助手'  // 固定描述文本
      },
      purpose: {
        title: '对话目的',
        workFocus: '工作辅助',
        emotionalSupport: '情感支持',
        casualConversation: '日常闲聊',
        informationRetrieval: '知识查询'
      },
      basePersonality: {
        title: '基础性格',
        logical: '理性分析型',
        warm: '温暖亲和型',
        humorous: '幽默风趣型',
        professional: '专业严谨型',
        direct: '直接果断型',
        empathetic: '共情理解型'
      },
      addOnTraits: {
        title: '性格特征',
        flirty: '调皮活泼',
        goodListener: '耐心倾听',
        coldJokes: '冷笑话爱好者',
        encouraging: '积极鼓励',
        philosophical: '富有哲思',
        sarcastic: '略带讽刺',
        taskOriented: '任务导向',
        interruptive: '主动引导',
        mysterious: '深邃神秘',
        picky: '追求完美',
        selfDeprecating: '幽默自嘲'
      },
      customPrompt: {
        title: '自定义提示词',
        purposePrompt: '对话目的提示词',
        basePrompt: '基础性格提示词',
        addOnPrompt: '附加特征提示词',
        purposeTitle: '自定义对话目的',
        basePersonalityTitle: '自定义基础性格',
        addOnTraitsTitle: '自定义附加特征',
        globalCustomTitle: '全局自定义提示词',
        purposeLabel: '对话目的提示词',
        basePersonalityLabel: '基础性格提示词',
        addOnTraitsLabel: '附加特征提示词',
        globalTitle: '全局自定义提示词',
        purposeTitle: '自定义对话目的',
        basePersonalityTitle: '自定义基础性格',
        addOnTraitsTitle: '自定义附加特征',
        purposeLabel: '对话目的提示词',
        basePersonalityLabel: '基础性格提示词',
        addOnTraitsLabel: '附加特征提示词'
      },
      saveButton: '保存设置',
      resetButton: '重置默认',
      saveSuccess: '设置保存成功！',
      saveError: '设置保存失败，请重试。',
      customizeThis: '自定义',
      custom: '自定义',
      globalCustom: '全局自定义',
      confirmClearCustom: '选择预设选项将清空当前的自定义内容，是否继续？'
    },
    message: {
      error: '抱歉，发生了错误，请稍后再试',
      connectionError: '连接失败，请检查网络后重试',
      loading: '正在思考...'
    },
    auth: {
      login: '登录',
      logout: '退出登录',
      loginSuccess: '登录成功',
      loginError: '登录失败',
      logoutSuccess: '已退出登录',
      welcome: '欢迎回来',
      loginRequired: '请先登录',
      authenticating: '正在验证...',
      sessionExpired: '登录已过期，请重新登录'
    }
    // 其他页面和组件的翻译
  },
  en: {
    homePage: {
      title: 'Home',
      chatWidget: 'Chat Window',
      calendarWidget: 'Calendar',
      todoWidget: 'To-Do List',
      exampleButton: 'Example Button',
      chatTab: 'Chat',
      calendarTab: 'Calendar'
    },
    preferences: {
      title: 'Preferences',
      language: 'Language',
      chinese: 'Chinese',
      english: 'English',
      timeFormat: 'Time Format',
      '12Hour': '12-hour',
      '24Hour': '24-hour',
      saveButton: 'Save Settings'
    },
    advancedSettings: {
      title: 'Advanced Settings',
      selectModel: 'Select Model',
      temperature: 'Temperature',
      openaiApiKey: 'OpenAI API Key',
      claudeApiKey: 'Claude API Key',
      nvidiaApiKey: 'Nvidia API Key',
      nvidiaBaseUrl: 'Nvidia Base URL',
      saveSettings: 'Save Settings',
      modelId: 'Model Version'
    },
    personalSettings: {
      title: 'Personal Settings',
      uiSettings: 'UI Settings',
      modelSettings: 'Model Settings',
      username: 'Username',
      userAvatar: 'User Avatar',
      userAvatarAlt: 'User Avatar',
      aiAvatar: 'AI Avatar',
      aiAvatarAlt: 'AI Avatar',
      occupation: 'Occupation',
      interests: 'Interests',
      backgroundInfo: 'Background Information',
      timeAwareness: 'Time Awareness',
      enableTimeAwareness: 'Enable Time Awareness',
      workDays: 'Work Days',
      workHours: 'Work Hours',
      sleepTime: 'Sleep Time',
      breaks: 'Breaks',
      lunch: 'Lunch Break',
      dinner: 'Dinner Break',
      timezone: 'Timezone',
      specialDates: 'Special Dates',
      saveSettings: 'Save Settings',
      saving: 'Saving...',
      saveSuccess: 'Settings Saved',
      saveError: 'Failed to Save',
      privacySettings: 'Privacy Settings',
      workDays: 'Work Days',
      sleepTime: 'Sleep Time',
      days: {
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday'
      },
      plannerNote: 'More detailed day planner functionality is coming soon...',
      addInterest: 'Add New Interest',
      add: 'Add',
      removeInterest: 'Remove Interest',
      interestPlaceholder: 'Enter new interests...'
    },
    settingsPage: {
      selectSettingsType: 'Select Settings Type',
      personalSettings: 'Personal Settings',
      preferencesSettings: 'Preferences Settings',
      advancedSettings: 'Advanced Settings',
      assistantSettings: 'Assistant Settings',
      close: 'Close'
    },
    themeToggle: {
      switchToLightMode: 'Switch to Light Mode',
      switchToDarkMode: 'Switch to Dark Mode'
    },
    settingsPopup: {
      personalSettings: 'Personal Settings',
      preferencesSettings: 'Preferences',
      advancedSettings: 'Advanced Settings',
      assistantSettings: 'Assistant Settings',
      darkMode: 'Dark Mode',
      lightMode: 'Light Mode'
    },
    inputField: {
      placeholder: 'Enter your message...',
      sendButton: 'Send message'
    },
    chatBox: {
      title: 'Chat Box',
      debugMode: 'Debug Mode',
      websocketConnected: 'WebSocket connection established',
      websocketClosed: 'WebSocket connection closed',
      websocketError: 'WebSocket error:',
      sendMessageError: 'Error sending message:'
    },
    daybreak: {
      button: 'Daybreak',
      confirmMessage: 'Are you sure you want to end the day? This will generate a summary for today and start a new day.',
      processing: 'Processing...'
    },
    common: {
      cancel: 'Cancel',
      confirm: 'Confirm',
      save: 'Save',
      saving: 'Saving...'
    },
    startPage: {
      aiAvatarAlt: 'AI Avatar',
      startButton: 'Start the Day'
    },
    assistantSettings: {
      title: 'Assistant Settings',
      identity: {
        title: 'Assistant Name',
        namePlaceholder: 'Enter assistant name (max 20 characters)',
        defaultName: 'Isaac',  // Default name
        description: 'A helpful AI assistant who is still under development but ready to help the user with anything'  // Fixed description
      },
      purpose: {
        title: 'Conversation Purpose',
        workFocus: 'Work Assistant',
        emotionalSupport: 'Emotional Support',
        casualConversation: 'Casual Chat',
        informationRetrieval: 'Knowledge Query'
      },
      basePersonality: {
        title: 'Base Personality',
        logical: 'Logical Analyzer',
        warm: 'Warm & Friendly',
        humorous: 'Humorous & Fun',
        professional: 'Professional & Precise',
        direct: 'Direct & Decisive',
        empathetic: 'Empathetic & Understanding'
      },
      addOnTraits: {
        title: 'Personality Traits',
        flirty: 'Playful & Lively',
        goodListener: 'Patient Listener',
        coldJokes: 'Cold Jokes Lover',
        encouraging: 'Encouraging',
        philosophical: 'Philosophical',
        sarcastic: 'Slightly Sarcastic',
        taskOriented: 'Task-Oriented',
        interruptive: 'Proactive Guide',
        mysterious: 'Mysterious',
        picky: 'Perfectionist',
        selfDeprecating: 'Self-Deprecating'
      },
      customPrompt: {
        title: 'Custom Prompts',
        purposePrompt: 'Purpose Prompt',
        basePrompt: 'Base Personality Prompt',
        addOnPrompt: 'Additional Traits Prompt',
        purposeTitle: 'Custom Conversation Purpose',
        basePersonalityTitle: 'Custom Base Personality',
        addOnTraitsTitle: 'Custom Additional Traits',
        globalCustomTitle: 'Global Custom Prompts',
        purposeLabel: 'Purpose Prompt',
        basePersonalityLabel: 'Base Personality Prompt',
        addOnTraitsLabel: 'Additional Traits Prompt',
        globalTitle: 'Global Custom Prompts',
        purposeTitle: 'Custom Conversation Purpose',
        basePersonalityTitle: 'Custom Base Personality',
        addOnTraitsTitle: 'Custom Additional Traits',
        purposeLabel: 'Purpose Prompt',
        basePersonalityLabel: 'Base Personality Prompt',
        addOnTraitsLabel: 'Additional Traits Prompt'
      },
      saveButton: 'Save Settings',
      resetButton: 'Reset to Default',
      saveSuccess: 'Settings saved successfully!',
      saveError: 'Failed to save settings. Please try again.',
      confirmClearCustom: 'Selecting a preset will clear your current custom content. Continue?'
    },
    message: {
      error: 'Sorry, an error occurred. Please try again later',
      connectionError: 'Connection failed, please check your network and try again',
      loading: 'Thinking...'
    },
    auth: {
      login: 'Login',
      logout: 'Logout',
      loginSuccess: 'Login Successful',
      loginError: 'Login Failed',
      logoutSuccess: 'Logged Out',
      welcome: 'Welcome Back',
      loginRequired: 'Please Login First',
      authenticating: 'Authenticating...',
      sessionExpired: 'Session expired, please login again'
    }
    // 其他页面和组件的翻译
  }
  // 可以添加更多语言
};
