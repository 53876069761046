import React from 'react';

const LoadingIndicator = () => (
  <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div className="w-12 h-12 relative">
      {[...Array(8)].map((_, index) => (
        <div
          key={index}
          className="absolute w-4 h-4 bg-white rounded-full transform animate-fadeLoader"
          style={{
            transform: `rotate(${index * 45}deg) translate(0, -150%)`,
            animationDelay: `${index * 0.15}s`,
          }}
        ></div>
      ))}
    </div>
  </div>
);

export default LoadingIndicator;
