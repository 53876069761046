import React from 'react';
import PersonalSettings from './PersonalSettings';
import PreferencesSettings from './PreferencesSettings';
import AdvancedSettings from './AdvancedSettings';
import AssistantSettings from './AssistantSettings';
import { useSettings } from '../../context/SettingsContext';
import { useTranslate } from '../../utils/translate';

function SettingsPage({ isOpen, onClose, activeTab, setActiveTab, isDarkMode }) {
  const { settings, updateSettings } = useSettings();
  const t = useTranslate();

  // 获取当前标签页的标题
  const getCurrentTabTitle = () => {
    switch (activeTab) {
      case 'personal':
        return t('settingsPage.personalSettings');
      case 'preferences':
        return t('settingsPage.preferencesSettings');
      case 'advanced':
        return t('settingsPage.advancedSettings');
      case 'assistant':
        return t('settingsPage.assistantSettings');
      default:
        return t('settingsPage.title');
    }
  };

  if (!isOpen) return null;

  const renderSettingsContent = () => {
    switch (activeTab) {
      case 'personal':
        return <PersonalSettings settings={settings} onUpdateSettings={updateSettings} />;
      case 'preferences':
        return <PreferencesSettings settings={settings} onUpdateSettings={updateSettings} />;
      case 'advanced':
        return <AdvancedSettings settings={settings} onUpdateSettings={updateSettings} />;
      case 'assistant':
        return <AssistantSettings />;
      default:
        return <div>{t('settingsPage.selectSettingsType')}</div>;
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center sm:p-4">
      <div className="w-full h-full sm:h-auto sm:max-w-2xl bg-major text-major sm:rounded-lg overflow-hidden">
        {/* 移动端标题栏 - 使用当前标签页的标题 */}
        <div className="sm:hidden sticky top-0 z-10 bg-major border-b border-outline px-4 py-3 flex justify-between items-center">
          <h1 className="text-lg font-medium">{getCurrentTabTitle()}</h1>
          <button 
            onClick={onClose}
            className="p-2 hover:bg-bg/10 rounded-full"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <div className="h-[calc(100vh-3.5rem)] sm:h-auto overflow-y-auto">
          <div className="hidden sm:flex sm:flex-row sm:p-4 sm:border-b">
            <button 
              className={`p-2 text-center rounded 
                         ${activeTab === 'personal' 
                           ? 'bg-secondary text-secondary' 
                           : 'hover:bg-bg/10'} 
                         mr-2`}
              onClick={() => setActiveTab('personal')}
            >
              {t('settingsPage.personalSettings')}
            </button>
            <button 
              className={`p-2 text-center rounded 
                         ${activeTab === 'preferences' 
                           ? 'bg-secondary text-secondary' 
                           : 'hover:bg-bg/10'} 
                         mr-2`}
              onClick={() => setActiveTab('preferences')}
            >
              {t('settingsPage.preferencesSettings')}
            </button>
            <button 
              className={`p-2 text-center rounded 
                         ${activeTab === 'advanced' 
                           ? 'bg-secondary text-secondary' 
                           : 'hover:bg-bg/10'} 
                         mr-2`}
              onClick={() => setActiveTab('advanced')}
            >
              {t('settingsPage.advancedSettings')}
            </button>
            <button 
              className={`p-2 text-center rounded 
                         ${activeTab === 'assistant' 
                           ? 'bg-secondary text-secondary' 
                           : 'hover:bg-bg/10'}`}
              onClick={() => setActiveTab('assistant')}
            >
              {t('settingsPage.assistantSettings')}
            </button>
          </div>

          <div className="p-4 sm:p-6">
            <div className="mb-4">
              {renderSettingsContent()}
            </div>
            <div className="flex justify-end">
              <button 
                onClick={onClose} 
                className="sm:block hidden bg-highlight text-highlight px-4 py-2 rounded hover:opacity-90"
              >
                {t('settingsPage.close')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SettingsPage;
