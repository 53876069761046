import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { API_URL, ENDPOINTS, getApiUrl } from '../config/apiConfig';
import { useAuth } from './AuthContext';

const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);
  const [isStreaming, setIsStreaming] = useState(false);
  const [currentDayId, setCurrentDayId] = useState(null);
  const { getAuthenticatedAxios } = useAuth();

  useEffect(() => {
    fetchCurrentDayId();
  }, []);

  const fetchCurrentDayId = async () => {
    try {
      const authAxios = await getAuthenticatedAxios();
      const response = await authAxios.get(getApiUrl(ENDPOINTS.CURRENT_DAY));
      console.log('获取到的 day_id 信息:', response.data);
      const { dayId, hasUserMessage } = response.data.currentDayId;
      console.log('当前 day_id:', dayId, '是否有用户消息:', hasUserMessage);
      setCurrentDayId(dayId);
      fetchChatHistory(dayId);
    } catch (error) {
      console.error('获取当前 day_id 时出错:', error);
    }
  };

  const fetchChatHistory = async (dayId) => {
    if (!dayId) return;
    console.log('获取聊天历史，day_id:', dayId);
    
    try {
      const authAxios = await getAuthenticatedAxios();
      const response = await authAxios.get(
        getApiUrl(`${ENDPOINTS.CHAT_HISTORY}/${dayId}`)
      );

      const sortedMessages = response.data.sort(
        (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
      );
      
      const formattedMessages = sortedMessages.map(msg => ({
        text: msg.message,
        sender: msg.sender === 'User' ? 'user' : 'ai',
        status: 'complete'
      }));
      
      console.log('格式化后的消息:', formattedMessages);
      setMessages(formattedMessages);
    } catch (error) {
      console.error('获取聊天历史时出错:', error);
    }
  };

  const addMessage = (message) => {
    setMessages(prevMessages => [...prevMessages, message]);
  };

  const updateLastMessage = (updater) => {
    setMessages(prevMessages => {
      const newMessages = [...prevMessages];
      const lastIndex = newMessages.length - 1;
      if (lastIndex >= 0) {
        newMessages[lastIndex] = updater(newMessages[lastIndex]);
      }
      return newMessages;
    });
  };
  
  const endDay = async () => {
    try {
      const authAxios = await getAuthenticatedAxios();
      const response = await authAxios.post(getApiUrl(ENDPOINTS.END_DAY));
      if (response.data.status === 'success') {
        setCurrentDayId(response.data.newDayId);
        setMessages([]);
        await fetchChatHistory(response.data.newDayId);
        return response.data.newDayId;
      }
    } catch (error) {
      console.error('结束一天时发生错误:', error);
      throw error;
    }
  };

  return (
    <ChatContext.Provider value={{ 
      messages, 
      setMessages,
      addMessage, 
      updateLastMessage, 
      isStreaming, 
      setIsStreaming,
      fetchChatHistory,
      currentDayId,
      setCurrentDayId,
      fetchCurrentDayId,
      endDay
    }}>
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => useContext(ChatContext);
