// API URLs
export const API_URL = process.env.REACT_APP_API_URL;
export const MODEL_URL = process.env.REACT_APP_MODEL_URL;
export const WS_URL = process.env.REACT_APP_WS_URL || 
    (window.location.protocol === 'https:' ? 
        'wss://projectisaac-server-fadbb8ete0dehkes.canadacentral-01.azurewebsites.net' : 
        'ws://localhost:3001');

console.log('WebSocket URL:', WS_URL); // 添加日志以便调试

// API Endpoints
export const ENDPOINTS = {
  // 数据库服务器 API
  CHAT: '/api/chat',
  CHAT_HISTORY: '/api/chat_history',
  CURRENT_DAY: '/api/current_day_id',
  END_DAY: '/api/end_day',
  SETTINGS: '/api/get_settings',
  DAILY_SUMMARIES: '/api/daily_summaries_by_date',
  USER_SETTINGS: '/api/user_settings',
  
  // 模型服务器 API
  ASSISTANT_PROFILE: '/assistant/profile',
  PERSONAL_PROFILE: '/personal/profile',
  MODEL_SETTINGS: '/update_settings',
  GET_SETTINGS: '/get_settings',

};

// 构建完整的 API URL
export const getApiUrl = (endpoint) => {
  // 添加日志以便调试
  console.log('Building API URL:', {
    API_URL,
    endpoint,
    fullUrl: `${API_URL}${endpoint}`
  });
  return `${API_URL}${endpoint}`;
};
export const getModelUrl = (endpoint) => `${MODEL_URL}${endpoint}`; 