import React, { useState, useEffect } from 'react';
import { useTranslate } from '../../utils/translate';
import { userConfig } from '../../config/userConfig';
import AvatarSettings from './AvatarSettings';
import { useAuth } from '../../context/AuthContext';
import { ENDPOINTS, getApiUrl } from '../../config/apiConfig';
import { usePreference } from '../../context/PreferenceContext';

function PreferencesSettings() {
  const { language, updatePreferences } = usePreference();
  const t = useTranslate();
  const [timeFormat, setTimeFormat] = useState(userConfig.timeFormat);
  const [isSaving, setIsSaving] = useState(false);
  const { getAuthenticatedAxios } = useAuth();

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const authAxios = await getAuthenticatedAxios();
        const response = await authAxios.get(getApiUrl(ENDPOINTS.USER_SETTINGS));
        if (response.data.timeFormat) {
          setTimeFormat(response.data.timeFormat);
          localStorage.setItem('timeFormat', response.data.timeFormat);
        }
      } catch (error) {
        console.error('获取设置失败:', error);
      }
    };
    fetchSettings();
  }, []);

  const handleLanguageChange = async (e) => {
    const newLanguage = e.target.value;
    await updatePreferences({ language: newLanguage });
  };

  const handleTimeFormatChange = (e) => {
    const newTimeFormat = e.target.value;
    setTimeFormat(newTimeFormat);
    localStorage.setItem('timeFormat', newTimeFormat);
  };

  const handleSave = async () => {
    try {
      setIsSaving(true);
      const authAxios = await getAuthenticatedAxios();
      const formData = new FormData();
      formData.append('timeFormat', timeFormat);
      formData.append('language', language);
      
      console.log('开始保存设置...');
      const response = await authAxios.post(
        getApiUrl(ENDPOINTS.USER_SETTINGS), 
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        }
      );
      
      if (response.data) {
        console.log('设置保存成功:', response.data);
        setTimeout(() => {
          setIsSaving(false);
          window.location.reload();
        }, 1000);
      }
    } catch (error) {
      console.error('保存设置失败:', error);
      setIsSaving(false);
    }
  };

  return (
    <div className="space-y-6">
      <h2 className="text-xl font-bold mb-4">{t('preferences.title')}</h2>
      
      {/* 语言设置 */}
      <div className="mb-4">
        <label className="block mb-2">{t('preferences.language')}：</label>
        <select
          value={language}
          onChange={handleLanguageChange}
          className="w-full p-2 border border-outline rounded bg-bg text-bg"
        >
          <option value="zh">{t('preferences.chinese')}</option>
          <option value="en">{t('preferences.english')}</option>
        </select>
      </div>

      {/* 时间格式设置 */}
      <div className="mb-4">
        <label className="block mb-2">{t('preferences.timeFormat')}：</label>
        <select
          value={timeFormat}
          onChange={handleTimeFormatChange}
          className="w-full p-2 border border-outline rounded bg-bg text-bg"
        >
          <option value="12">{t('preferences.12Hour')}</option>
          <option value="24">{t('preferences.24Hour')}</option>
        </select>
      </div>

      {/* 头像设置 */}
      <div className="mb-8">
        <h3 className="text-lg font-semibold mb-4">{t('personalSettings.title')}</h3>
        <AvatarSettings />
      </div>

      <button
        onClick={handleSave}
        disabled={isSaving}
        className="bg-highlight text-highlight px-4 py-2 rounded disabled:opacity-50"
      >
        {isSaving ? t('preferences.saving') : t('preferences.saveButton')}
      </button>
    </div>
  );
}

export default PreferencesSettings;
