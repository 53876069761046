import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { ENDPOINTS, getApiUrl } from '../../config/apiConfig';
import { useAuth } from '../../context/AuthContext';
import { usePreference } from '../../context/PreferenceContext';

const CalendarDayCard = ({ date }) => {
  const [summaries, setSummaries] = useState([]);
  const { language } = usePreference();
  const { getAuthenticatedAxios } = useAuth();

  useEffect(() => {
    fetchSummaries(date);
  }, [date]);

  const fetchSummaries = async (date) => {
    try {
      const formattedDate = date.toLocaleDateString('en-CA');
      console.log('请求日期:', formattedDate, '原始日期对象:', date, '本地时间:', new Date(date).toLocaleString());
      
      const authAxios = await getAuthenticatedAxios();
      if (!authAxios || typeof authAxios.get !== 'function') {
        throw new Error('获取认证的 axios 实例失败');
      }
      
      const response = await authAxios.get(
        getApiUrl(`${ENDPOINTS.DAILY_SUMMARIES}/${formattedDate}`)
      );
      
      console.log('收到的摘要数据:', response.data);
      setSummaries(response.data);
    } catch (error) {
      console.error('获取日志摘要时出错:', error);
      setSummaries([]); // 出错时设置为空数组
    }
  };

  return (
    <div className="bg-bg rounded-lg shadow-lg p-4 max-h-[calc(100vh-500px)] overflow-y-auto">
      <h3 className="text-lg font-bold mb-2">
        {date.toLocaleDateString(language, { year: 'numeric', month: 'long', day: 'numeric' })}
      </h3>
      {summaries.length > 0 ? (
        <>
          <div className="mb-4">
            <h4 className="font-semibold">最新日志</h4>
            <div className="prose max-w-none">
              <ReactMarkdown>{summaries[0].summary}</ReactMarkdown>
            </div>
          </div>
          {summaries.length > 1 && (
            <details>
              <summary className="cursor-pointer text-blue-500">查看历史日志</summary>
              {summaries.slice(1).map((summary, index) => (
                <div key={index} className="mt-2">
                  <h4 className="font-semibold">日志 {index + 2}</h4>
                  <div className="prose max-w-none">
                    <ReactMarkdown>{summary.summary}</ReactMarkdown>
                  </div>
                </div>
              ))}
            </details>
          )}
        </>
      ) : (
        <p>该日期没有日志记录</p>
      )}
    </div>
  );
};

export default CalendarDayCard;
