import React, { useState } from 'react';
import { useTranslate } from '../../utils/translate';
import { userConfig } from '../../config/userConfig';
import ImageCropDialog from '../ImageCropDialog';
import { usePreference } from '../../context/PreferenceContext';
import { useAuth } from '../../context/AuthContext';

function AvatarSettings() {
  const t = useTranslate();
  const { avatars, updatePreferences } = usePreference();
  
  const [currentImage, setCurrentImage] = useState(null);
  const [isUserAvatarCrop, setIsUserAvatarCrop] = useState(true);
  const [isCropDialogOpen, setIsCropDialogOpen] = useState(false);

  const handleAvatarChange = (event, isUserAvatar) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        console.log('读取到的图片数据:', {
          hasData: !!reader.result,
          dataLength: reader.result?.length,
          dataType: typeof reader.result
        });
        
        setCurrentImage(reader.result);
        setIsUserAvatarCrop(isUserAvatar);
        setIsCropDialogOpen(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCropConfirm = async (croppedImage) => {
    try {
      console.log('开始处理裁剪后的头像...', {
        isUserAvatarCrop,
        imageSize: croppedImage.length
      });

      const updates = isUserAvatarCrop 
        ? { userAvatar: croppedImage }
        : { aiAvatar: croppedImage };
        
      await updatePreferences(updates);
      console.log('头像更新成功');
      
      setIsCropDialogOpen(false);
      setCurrentImage(null);
    } catch (error) {
      console.error('更新头像失败:', error);
    }
  };

  return (
    <div>
      <div className="mb-4">
        <label className="block mb-2">{t('personalSettings.userAvatar')}：</label>
        <div className="flex items-center">
          <img 
            src={avatars.user || userConfig.defaultUserAvatar} 
            alt={t('personalSettings.userAvatarAlt')} 
            className="w-16 h-16 rounded-full mr-4" 
          />
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleAvatarChange(e, true)}
            className="bg-secondary text-secondary p-2 rounded"
          />
        </div>
      </div>

      <div className="mb-4">
        <label className="block mb-2">{t('personalSettings.aiAvatar')}：</label>
        <div className="flex items-center">
          <img 
            src={avatars.ai || userConfig.defaultAIAvatar} 
            alt={t('personalSettings.aiAvatarAlt')} 
            className="w-16 h-16 rounded-full mr-4" 
          />
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleAvatarChange(e, false)}
            className="bg-secondary text-secondary p-2 rounded"
          />
        </div>
      </div>

      <ImageCropDialog
        isOpen={isCropDialogOpen}
        onClose={() => setIsCropDialogOpen(false)}
        onConfirm={handleCropConfirm}
        image={currentImage}
      />
    </div>
  );
}

export default AvatarSettings; 