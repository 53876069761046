import defaultUserAvatar from '../assets/images/user-avatar.png';
import defaultAIAvatar from '../assets/images/ai-avatar.png';

const savedDarkMode = localStorage.getItem('isDarkMode');
const savedTimeFormat = localStorage.getItem('timeFormat');

export const userConfig = {
    // 保留默认头像作为fallback
    defaultUserAvatar,
    defaultAIAvatar,
    timeFormat: savedTimeFormat || '24',
    isDarkMode: savedDarkMode ? JSON.parse(savedDarkMode) : false,
};
