import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { ThemeProvider } from './context/ThemeContext';
import { SettingsProvider, useSettings } from './context/SettingsContext';
import { ChatProvider, useChat } from './context/ChatContext';
import { AuthProvider } from './context/AuthContext';
import LoadingIndicator from './components/LoadingIndicator';
import { AssistantProvider } from './context/AssistantContext';
import { PersonalProvider } from './context/PersonalContext';
import { Auth0Provider } from '@auth0/auth0-react';
import { auth0Config } from './config/auth0Config';
import { PreferenceProvider, usePreference } from './context/PreferenceContext';
import { userConfig } from './config/userConfig';

import StartPage from './pages/StartPage';
import HomePage from './pages/HomePage';
import SettingsPage from './components/settings/SettingsPage';
import SettingsPopup from './components/settings/SettingsPopup';
import Daybreak from './components/daybreak';
import { useTranslate } from './utils/translate';
import ConfirmDialog from './components/ConfirmDialog';
import TransitionOverlay from './components/TSFade';

function AppContent() {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [showSettingsPopup, setShowSettingsPopup] = useState(false);
  const [showSettingsPage, setShowSettingsPage] = useState(false);
  const [activeSettingsTab, setActiveSettingsTab] = useState('personal');
  const { settings } = useSettings();
  const navigate = useNavigate();
  const t = useTranslate();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const location = useLocation();
  const [isTransitioning, setIsTransitioning] = useState(false);
  const { endDay, currentDayId, fetchCurrentDayId } = useChat();
  const [isEndingDay, setIsEndingDay] = useState(false);
  const { avatars } = usePreference();

  useEffect(() => {
    const timer = setInterval(() => setCurrentTime(new Date()), 60000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (settings.isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [settings.isDarkMode]);

  useEffect(() => {
    if (!currentDayId) {
      fetchCurrentDayId();
    }
  }, [currentDayId, fetchCurrentDayId]);

  const toggleSettingsPopup = () => {
    setShowSettingsPopup(!showSettingsPopup);
  };

  const openSettingsPage = (tab) => {
    setActiveSettingsTab(tab);
    setShowSettingsPage(true);
    setShowSettingsPopup(false);
  };

  const formatTime = (date) => {
    const options = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: settings.timeFormat === '12'
    };
    return date.toLocaleTimeString([], options);
  };

  const handleDaybreakClick = () => {
    setShowConfirmDialog(true);
  };

  const navigateWithTransition = useCallback((to) => {
    setIsTransitioning(true);
    setTimeout(() => {
      navigate(to);
      setTimeout(() => {
        setIsTransitioning(false);
      }, 150); // 与过渡动画持续时间相匹配
    }, 450); // 给过渡动画一些时间来覆盖屏幕0
  }, [navigate]);

  const handleConfirm = async () => {
    try {
      setIsEndingDay(true);
      const newDayId = await endDay();
      setIsEndingDay(false);
      setShowConfirmDialog(false);
      // 确保 currentDayId 已更新后再导航
      if (newDayId) {
        navigateWithTransition('/');
      }
    } catch (error) {
      console.error('结束一天时发生错误:', error);
      setIsEndingDay(false);
      // 在这里可以添加错误处理，比如显示一个错误提示
    }
  };


  console.log('API URL:', process.env.REACT_APP_API_URL);
  console.log('MODEL URL:', process.env.REACT_APP_MODEL_URL);
  console.log('WS URL:', process.env.REACT_APP_WS_URL);

  const showNavbar = location.pathname !== '/';

  return (
    <div className="bg-app flex flex-col h-screen">
      <TransitionOverlay isTransitioning={isTransitioning} />
      {showNavbar && (
        <nav className="bg-app-nav p-2 sm:p-4 flex justify-between items-center">
          <div className="text-inherit text-sm sm:text-base">{formatTime(currentTime)}</div>
          <div className="flex items-center">
            <Daybreak onClick={handleDaybreakClick} className="mr-2 sm:mr-4" />
            <div className="relative">
              <button 
                className="w-8 h-8 sm:w-10 sm:h-10 rounded-full overflow-hidden"
                onClick={toggleSettingsPopup}
              >
                <img 
                  src={avatars.user || userConfig.defaultUserAvatar} 
                  alt="用户头像" 
                  className="w-full h-full object-cover"
                />
              </button>
              {showSettingsPopup && (
                <div className="absolute right-0 mt-2 w-screen sm:w-auto mx-4 sm:mx-0">
                  <SettingsPopup onClose={toggleSettingsPopup} onOpenSettings={openSettingsPage} />
                </div>
              )}
            </div>
          </div>
        </nav>
      )}
      <main className={`flex-1 bg-app overflow-hidden ${!showNavbar ? 'h-screen' : ''}`}>
        <Routes>
          <Route path="/" element={<StartPage navigateWithTransition={navigateWithTransition} />} />
          <Route path="/home" element={<HomePage />} />
        </Routes>
        {showSettingsPage && (
          <SettingsPage
            isOpen={showSettingsPage}
            onClose={() => setShowSettingsPage(false)}
            activeTab={activeSettingsTab}
            setActiveTab={setActiveSettingsTab}
            isDarkMode={settings.isDarkMode}
          />
        )}
      </main>
      <ConfirmDialog
        isOpen={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        onConfirm={handleConfirm}
        message={t('daybreak.confirmMessage')}
        confirmText={isEndingDay ? t('daybreak.processing') : t('common.confirm')}
        cancelText={t('common.cancel')}
        isConfirmDisabled={isEndingDay}
      />
      {isEndingDay && <LoadingIndicator />}
    </div>
  );
}

function App() {
  return (
    <Auth0Provider
      domain={auth0Config.domain}
      clientId={auth0Config.clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: auth0Config.audience,
        scope: "openid profile email"
      }}
      loginOptions={{
        screen_hint: "signup",
        auth: {
          responseType: 'token id_token',
          prompt: 'login',
        }
      }}
    >
      <Router>
        <AuthProvider>
          <PreferenceProvider>
            <ThemeProvider>
              <SettingsProvider>
                <PersonalProvider>
                  <AssistantProvider>
                    <ChatProvider>
                      <AppContent />
                    </ChatProvider>
                  </AssistantProvider>
                </PersonalProvider>
              </SettingsProvider>
            </ThemeProvider>
          </PreferenceProvider>
        </AuthProvider>
      </Router>
    </Auth0Provider>
  );
}

export default App;
