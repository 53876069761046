import React, { createContext, useState, useContext, useEffect } from 'react';
import { userConfig } from '../config/userConfig';
import axios from 'axios';
import { API_URL, MODEL_URL, ENDPOINTS, getApiUrl, getModelUrl } from '../config/apiConfig';
import { useAuth } from './AuthContext';

const SettingsContext = createContext();

const defaultModelSettings = {
  models: [],
  selected_model: '',
  current_settings: {}
};

export const useSettings = () => useContext(SettingsContext);

export const SettingsProvider = ({ children }) => {
  const [settings, setSettings] = useState(userConfig);
  const [modelSettings, setModelSettings] = useState(defaultModelSettings);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getAuthenticatedAxios } = useAuth();

  const fetchSettings = async () => {
    setError(null);
    setIsLoading(true);
    
    try {
      const authAxios = await getAuthenticatedAxios();
      const response = await authAxios.get(getModelUrl(ENDPOINTS.GET_SETTINGS));
      
      const safeData = {
        ...defaultModelSettings,
        ...(response.data || {})
      };
      
      setModelSettings(safeData);
      return safeData;
    } catch (error) {
      console.error('获取设置时出错:', error);
      setError(error);
      setModelSettings(defaultModelSettings);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const updateModelSettings = async (newSettings) => {
    setError(null);
    
    try {
      const authAxios = await getAuthenticatedAxios();
      const response = await authAxios.post(
        getModelUrl(ENDPOINTS.MODEL_SETTINGS), 
        JSON.stringify(newSettings), 
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      
      if (response.data?.current_settings) {
        const updatedSettings = {
          ...defaultModelSettings,
          ...modelSettings,
          current_settings: response.data.current_settings
        };
        setModelSettings(updatedSettings);
        return updatedSettings;
      }
      
      return response.data;
    } catch (error) {
      console.error('更新设置时出错:', error);
      setError(error);
      throw error;
    }
  };

  useEffect(() => {
    let mounted = true;

    const initializeSettings = async () => {
      try {
        if (mounted) {
          await fetchSettings();
        }
      } catch (error) {
        console.error('初始化设置时出错:', error);
      }
    };

    initializeSettings();

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    const root = document.documentElement;
    if (settings.isDarkMode) {
      root.classList.add('dark');
    } else {
      root.classList.remove('dark');
    }
    
    root.style.backgroundColor = root.style.backgroundColor;
  }, [settings.isDarkMode]);

  const updateSettings = (newSettings) => {
    setSettings(prevSettings => ({ ...prevSettings, ...newSettings }));
  };

  const toggleDarkMode = () => {
    setSettings(prevSettings => {
      const newIsDarkMode = !prevSettings.isDarkMode;
      localStorage.setItem('isDarkMode', JSON.stringify(newIsDarkMode));
      return { ...prevSettings, isDarkMode: newIsDarkMode };
    });
  };

  return (
    <SettingsContext.Provider value={{
      settings,
      updateSettings,
      toggleDarkMode,
      modelSettings,
      fetchSettings,
      updateModelSettings,
      isLoading,
      error
    }}>
      {children}
    </SettingsContext.Provider>
  );
};
