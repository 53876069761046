import React from 'react';
import { FaSun, FaMoon } from 'react-icons/fa';
import { useSettings } from '../../context/SettingsContext';
import { useTranslate } from '../../utils/translate';

function ThemeToggle() {
  const { settings, toggleDarkMode } = useSettings();
  const isDarkMode = settings.isDarkMode;
  const t = useTranslate();

  return (
    <button 
      className={`w-14 h-7 relative flex-shrink-0 rounded-full p-0.5 transition-colors duration-300 ease-in-out focus:outline-none ${
        isDarkMode ? 'bg-gray-600' : 'bg-gray-300'
      }`}
      onClick={toggleDarkMode}
      aria-label={isDarkMode ? t('themeToggle.switchToLightMode') : t('themeToggle.switchToDarkMode')}
    >
      <div 
        className={`w-6 h-6 rounded-full bg-white shadow-md transform transition-transform duration-300 ease-in-out flex items-center justify-center ${
          isDarkMode ? 'translate-x-7 bg-gray-800' : 'translate-x-0'
        }`}
      >
        {isDarkMode ? (
          <FaMoon className="w-3.5 h-3.5 text-yellow-300" />
        ) : (
          <FaSun className="w-3.5 h-3.5 text-yellow-500" />
        )}
      </div>
    </button>
  );
}

export default ThemeToggle;
