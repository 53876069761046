import React, { useState, useEffect } from 'react';
import { useAssistant } from '../../context/AssistantContext';
import { useTranslate } from '../../utils/translate';
import { ASSISTANT_PRESETS } from '../../config/assistantPresets';
import CustomPromptModal from './CustomPromptModal';
import ConfirmDialog from '../ConfirmDialog';

function AssistantSettings() {
  const t = useTranslate();
  const { currentProfile, setCurrentProfile, updateAssistantProfile, isLoading } = useAssistant();
  const [saveMessage, setSaveMessage] = useState('');
  const [showCustomModals, setShowCustomModals] = useState({
    purpose: false,
    basePersonality: false,
    addOnTraits: false,
    global: false  // 原来的全局自定义
  });
  const [isSaving, setIsSaving] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [pendingAction, setPendingAction] = useState(null);
  
  const MUTUALLY_EXCLUSIVE_TRAITS = {
    'flirty': ['professional', 'taskOriented'],
    'sarcastic': ['empathetic', 'warm'],
    'mysterious': ['direct', 'logical'],
    // ... 其他互斥关系
  };

  // 添加localStorage持久化
  useEffect(() => {
    const savedProfile = localStorage.getItem('assistantProfile');
    if (savedProfile) {
      setCurrentProfile(JSON.parse(savedProfile));
    }
  }, []);

  // 保存到localStorage
  useEffect(() => {
    if (currentProfile) {
      localStorage.setItem('assistantProfile', JSON.stringify(currentProfile));
    }
  }, [currentProfile]);

  // 处理目的选择
  const handlePurposeChange = (purpose) => {
    if (currentProfile.customPrompts.purpose) {
      setPendingAction(() => () => {
        setCurrentProfile(prev => ({
          ...prev,
          purpose,
          customPrompts: {
            ...prev.customPrompts,
            purpose: ''
          }
        }));
      });
      setShowConfirmDialog(true);
    } else {
      setCurrentProfile(prev => ({
        ...prev,
        purpose
      }));
    }
  };

  // 处理基础性格选择
  const handleBasePersonalityChange = (personality) => {
    if (currentProfile.customPrompts.basePersonality) {
      setPendingAction(() => () => {
        setCurrentProfile(prev => ({
          ...prev,
          basePersonality: personality,
          customPrompts: {
            ...prev.customPrompts,
            basePersonality: ''
          }
        }));
      });
      setShowConfirmDialog(true);
    } else {
      setCurrentProfile(prev => ({
        ...prev,
        basePersonality: personality
      }));
    }
  };

  // 处理特征选择
  const handleTraitToggle = (trait) => {
    setCurrentProfile(prev => {
      // 确保 prev 和 prev.addOnTraits 存在
      const currentProfile = prev || {
        purpose: '',
        basePersonality: '',
        addOnTraits: [],
        customPrompts: {
          purpose: '',
          basePersonality: '',
          addOnTraits: ''
        }
      };
      
      const currentTraits = currentProfile.addOnTraits || [];
      const isAdding = !currentTraits.includes(trait);
      
      if (isAdding) {
        // 检查互斥特征
        const conflictingTraits = MUTUALLY_EXCLUSIVE_TRAITS[trait] || [];
        const newTraits = currentTraits.filter(t => !conflictingTraits.includes(t));
        return {
          ...currentProfile,
          addOnTraits: [...newTraits, trait]
        };
      } else {
        return {
          ...currentProfile,
          addOnTraits: currentTraits.filter(t => t !== trait)
        };
      }
    });
  };

  // 保存设置
  const handleSave = async () => {
    setIsSaving(true);
    try {
      const success = await updateAssistantProfile(currentProfile);
      setSaveMessage(success ? t('assistantSettings.saveSuccess') : t('assistantSettings.saveError'));
    } finally {
      setIsSaving(false);
      setTimeout(() => setSaveMessage(''), 3000);
    }
  };

  console.log('Current loading state:', isLoading); // 添加调试日志
  console.log('Current profile:', currentProfile); // 添加调试日志

  // 如果正在加载，显示加载状态
  if (isLoading) {
    return (
      <div className="bg-bg p-4 rounded-lg shadow flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-secondary"></div>
      </div>
    );
  }

  // 如果 currentProfile 未定义，使用默认值
  const profile = currentProfile || {
    purpose: '',
    basePersonality: '',
    addOnTraits: [],
    customPrompts: {
      purpose: '',
      basePersonality: '',
      addOnTraits: ''
    }
  };

  // 处理自定义按钮点击
  const handleCustomClick = (section) => {
    setShowCustomModals(prev => ({
      ...prev,
      [section]: true
    }));
  };

  // 处理确认对话框
  const handleConfirm = () => {
    if (pendingAction) {
      pendingAction();
      setPendingAction(null);
    }
    setShowConfirmDialog(false);
  };

  return (
    <div className="bg-bg p-4 rounded-lg shadow">
      <h3 className="text-xl font-bold mb-4 text-inherit">
        {t('assistantSettings.title')}
      </h3>

      {/* AI助手名字设置 */}
      <section className="mb-6">
        <div className="flex justify-between items-center mb-2">
          <h4 className="font-medium">{t('assistantSettings.identity.title')}</h4>
        </div>
        <div className="flex items-center gap-2">
          <input
            type="text"
            value={currentProfile.identity?.name || ''}
            onChange={(e) => setCurrentProfile(prev => ({
              ...prev,
              identity: {
                ...prev.identity,
                name: e.target.value
              }
            }))}
            placeholder={t('assistantSettings.identity.namePlaceholder')}
            className="flex-1 p-2 rounded bg-button-secondary focus:outline-none focus:ring-2 focus:ring-highlight"
            maxLength={20}
          />
        </div>
      </section>

      {/* 对话目的选择 */}
      <section className="mb-6">
        <div className="flex justify-between items-center mb-2">
          <h4 className="font-medium">{t('assistantSettings.purpose.title')}</h4>
          <button
            onClick={() => handleCustomClick('purpose')}
            className={`px-2 py-1 text-sm rounded hover:bg-opacity-80 ${
              currentProfile.customPrompts.purpose 
                ? 'bg-highlight text-highlight' 
                : 'bg-button-secondary'
            }`}
          >
            {t('assistantSettings.customizeThis')}
          </button>
        </div>
        <div className="grid grid-cols-2 gap-2">
          {Object.keys(ASSISTANT_PRESETS.purposes).map(purpose => (
            <button
              key={purpose}
              onClick={() => handlePurposeChange(purpose)}
              className={`p-2 rounded transition-colors duration-200 ${
                currentProfile.purpose === purpose && !currentProfile.customPrompts.purpose
                  ? 'bg-highlight text-highlight font-medium'
                  : 'bg-button-secondary hover:bg-opacity-80'
              }`}
            >
              {t(`assistantSettings.purpose.${purpose}`)}
            </button>
          ))}
        </div>
      </section>

      {/* 基础性格选择 */}
      <section className="mb-6">
        <div className="flex justify-between items-center mb-2">
          <h4 className="font-medium">{t('assistantSettings.basePersonality.title')}</h4>
          <button
            onClick={() => handleCustomClick('basePersonality')}
            className={`px-2 py-1 text-sm rounded hover:bg-opacity-80 ${
              currentProfile.customPrompts.basePersonality 
                ? 'bg-highlight text-highlight' 
                : 'bg-button-secondary'
            }`}
          >
            {t('assistantSettings.customizeThis')}
          </button>
        </div>
        <div className="grid grid-cols-2 gap-2">
          {['logical', 'warm', 'humorous', 'professional', 'direct', 'empathetic'].map(personality => (
            <button
              key={personality}
              onClick={() => handleBasePersonalityChange(personality)}
              className={`p-2 rounded transition-colors duration-200 ${
                currentProfile.basePersonality === personality && !currentProfile.customPrompts.basePersonality
                  ? 'bg-highlight text-highlight font-medium'
                  : 'bg-button-secondary hover:bg-opacity-80'
              }`}
            >
              {t(`assistantSettings.basePersonality.${personality}`)}
            </button>
          ))}
        </div>
      </section>

      {/* 附加特征选择 */}
      <section className="mb-6">
        <div className="flex justify-between items-center mb-2">
          <h4 className="font-medium">{t('assistantSettings.addOnTraits.title')}</h4>
          <button
            onClick={() => handleCustomClick('addOnTraits')}
            className={`px-2 py-1 text-sm rounded hover:bg-opacity-80 ${
              currentProfile.customPrompts.addOnTraits 
                ? 'bg-highlight text-highlight' 
                : 'bg-button-secondary'
            }`}
          >
            {t('assistantSettings.customizeThis')}
          </button>
        </div>
        <div className="grid grid-cols-3 gap-2">
          {[
            'flirty', 'goodListener', 'coldJokes', 'encouraging', 
            'philosophical', 'sarcastic', 'taskOriented', 'interruptive', 
            'mysterious', 'picky', 'selfDeprecating'
          ].map(trait => (
            <button
              key={trait}
              onClick={() => handleTraitToggle(trait)}
              className={`p-2 rounded transition-colors duration-200 ${
                profile.addOnTraits?.includes(trait)
                  ? 'bg-highlight text-highlight font-medium'
                  : 'bg-button-secondary hover:bg-opacity-80'
              } ${
                Object.entries(MUTUALLY_EXCLUSIVE_TRAITS).some(([key, conflicts]) => 
                  conflicts.includes(trait) && profile.addOnTraits?.includes(key)
                ) ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={Object.entries(MUTUALLY_EXCLUSIVE_TRAITS).some(([key, conflicts]) => 
                conflicts.includes(trait) && profile.addOnTraits?.includes(key)
              )}
            >
              {t(`assistantSettings.addOnTraits.${trait}`)}
            </button>
          ))}
        </div>
      </section>

      {/* 底部按钮区域 */}
      <div className="flex justify-between items-center mt-6">
        <button 
          onClick={() => handleCustomClick('global')}
          className="px-4 py-2 bg-highlight text-highlight rounded hover:opacity-90"
        >
          {t('assistantSettings.globalCustom')}
        </button>
        {/* 自定义按钮 */}
        <button 
          onClick={handleSave}
          disabled={isSaving}
          className={`px-4 py-2 rounded text-inherit ${
            isSaving ? 'bg-button-secondary opacity-50' : 'bg-button-secondary'
          }`}
        >
          {isSaving ? t('common.saving') : t('assistantSettings.saveButton')}
        </button>

      </div>

      {/* 保存消息 */}
      {saveMessage && (
        <div className="mt-4 p-2 bg-highlight text-inherit rounded">
          {saveMessage}
        </div>
      )}

      {/* 各个自定义模态框 */}
      <CustomPromptModal 
        isOpen={showCustomModals.purpose}
        onClose={() => setShowCustomModals(prev => ({...prev, purpose: false}))}
        currentProfile={currentProfile}
        setCurrentProfile={setCurrentProfile}
        section="purpose"
      />
      <CustomPromptModal 
        isOpen={showCustomModals.basePersonality}
        onClose={() => setShowCustomModals(prev => ({...prev, basePersonality: false}))}
        currentProfile={currentProfile}
        setCurrentProfile={setCurrentProfile}
        section="basePersonality"
      />
      <CustomPromptModal 
        isOpen={showCustomModals.addOnTraits}
        onClose={() => setShowCustomModals(prev => ({...prev, addOnTraits: false}))}
        currentProfile={currentProfile}
        setCurrentProfile={setCurrentProfile}
        section="addOnTraits"
      />
      <CustomPromptModal 
        isOpen={showCustomModals.global}
        onClose={() => setShowCustomModals(prev => ({...prev, global: false}))}
        currentProfile={currentProfile}
        setCurrentProfile={setCurrentProfile}
        section="global"
      />

      <ConfirmDialog
        isOpen={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        onConfirm={handleConfirm}
        message={t('assistantSettings.confirmClearCustom')}
      />
    </div>
  );
}

export default AssistantSettings; 