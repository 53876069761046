import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslate } from '../utils/translate';
import { userConfig } from '../config/userConfig';
import { useChat } from '../context/ChatContext';
import LoadingIndicator from '../components/LoadingIndicator';
import { useAuth0 } from '@auth0/auth0-react';
import { usePreference } from '../context/PreferenceContext';

const StartPage = ({ navigateWithTransition }) => {
  const t = useTranslate();
  const { currentDayId, fetchCurrentDayId } = useChat();
  const [isLoading, setIsLoading] = useState(false);
  const { isAuthenticated, isLoading: authLoading, loginWithRedirect, logout, user } = useAuth0();
  const { avatars } = usePreference();

  const handleStart = async () => {
    setIsLoading(true);
    try {
      // 强制获取最新的 day id
      await fetchCurrentDayId();
      navigateWithTransition('/home');
    } catch (error) {
      console.error('获取当前日期ID时出错:', error);
      // 这里可以添加错误处理，比如显示一个错误提示
    } finally {
      setIsLoading(false);
    }
  };

  const loginOptions = {
    screen_hint: "signup",
    authorizationParams: {
      prompt: "login",
    }
  };

  if (authLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div className="bg-app flex flex-col items-center justify-center min-h-screen p-4">
      {isLoading && <LoadingIndicator />}
      <div className="text-center w-full max-w-md">
        <div className="relative w-24 sm:w-32 h-24 sm:h-32 mx-auto mb-6 sm:mb-8">
          <div className="absolute inset-0 bg-highlight rounded-full opacity-30 animate-pulse"></div>
          <img 
            src={avatars.ai || userConfig.defaultAIAvatar} 
            alt={t('startPage.aiAvatarAlt')} 
            className="relative z-10 w-full h-full rounded-full animate-breathe"
          />
        </div>
        <h1 className="text-major text-xl sm:text-2xl font-bold mb-6 sm:mb-8">
          "Embrace the day with courage and kindness."
        </h1>
        <div className="flex flex-col gap-4 items-center">
          {!isAuthenticated ? (
            <button 
              onClick={() => loginWithRedirect(loginOptions)}
              className="bg-highlight text-highlight px-4 sm:px-6 py-2.5 sm:py-3 rounded-full text-base sm:text-lg font-semibold hover:bg-secondary hover:text-secondary transition duration-300 w-full sm:w-auto"
            >
              {t('auth.login')}
            </button>
          ) : (
            <>
              <button 
                onClick={handleStart}
                className="bg-highlight text-highlight px-4 sm:px-6 py-2.5 sm:py-3 rounded-full text-base sm:text-lg font-semibold hover:bg-secondary hover:text-secondary transition duration-300 w-full sm:w-auto"
                disabled={isLoading}
              >
                {t('startPage.startButton')}
              </button>
              <button 
                onClick={() => logout({ returnTo: window.location.origin })}
                className="bg-highlight text-highlight px-4 sm:px-6 py-2.5 sm:py-3 rounded-full text-base sm:text-lg font-semibold hover:bg-secondary hover:text-secondary transition duration-300 w-full sm:w-auto"
              >
                {t('auth.logout')}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default StartPage;
