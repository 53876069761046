import React, { useRef, useEffect } from 'react';
import { FiSend } from 'react-icons/fi';
import { useTranslate } from '../../utils/translate';

const InputField = ({ value, onChange, onSend, isStreaming }) => {
  const textareaRef = useRef(null);
  const t = useTranslate();

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [value]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onSend();
    }
  };

  return (
    <div className="relative">
      <textarea
        ref={textareaRef}
        value={value}
        onChange={onChange}
        onKeyPress={handleKeyPress}
        className="input-textarea text-text-light dark:text-text-dark"
        placeholder={t('inputField.placeholder')}
        disabled={isStreaming}
        rows={2}
        style={{ minHeight: '3rem' }}
      />
      <button
        onClick={onSend}
        className="button-send"
        disabled={isStreaming}
        aria-label={t('inputField.sendButton')}
      >
        <FiSend size={20} className="text-inherit" />
      </button>
    </div>
  );
};

export default InputField;
