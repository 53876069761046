import React, { useState, useEffect } from 'react';
import 'react-calendar/dist/Calendar.css';
import CalendarDayCard from './CalendarDayCard';
import { usePreference } from '../../context/PreferenceContext';
import { SlArrowLeft, SlArrowRight } from 'react-icons/sl';

const CalendarWidget = () => {
  const { language } = usePreference();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [weekOffset, setWeekOffset] = useState(0);
  const [showDayCard, setShowDayCard] = useState(false);

  const getWeekDates = (date, offset = 0) => {
    const week = [];
    const current = new Date(date);
    current.setDate(current.getDate() - current.getDay() + (offset * 7));
    for (let i = 0; i < 7; i++) {
      const newDate = new Date(current);
      week.push(newDate);
      current.setDate(current.getDate() + 1);
    }
    return week;
  };

  const [currentWeek, setCurrentWeek] = useState(() => getWeekDates(new Date()));

  useEffect(() => {
    const week = getWeekDates(new Date(), weekOffset);
    setCurrentWeek(week);
  }, [weekOffset]);

  const formatDate = (date) => {
    if (!date) return '';
    return date.toLocaleDateString(language, { month: 'numeric', day: 'numeric' });
  };

  const formatWeekday = (date) => {
    if (!date) return '';
    return date.toLocaleDateString(language, { weekday: 'short' });
  };

  const handleDayClick = (date) => {
    setSelectedDate(date);
    if (window.innerWidth < 640) {
      setShowDayCard(true);
    }
  };

  const handleCloseDayCard = () => {
    setShowDayCard(false);
  };

  const handlePrevWeek = () => {
    setWeekOffset(prev => prev - 1);
  };

  const handleNextWeek = () => {
    setWeekOffset(prev => prev + 1);
  };

  if (!currentWeek || currentWeek.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-major rounded-lg shadow-md p-4">
      <div className="hidden sm:flex items-center justify-between mb-4">
        <button 
          onClick={handlePrevWeek}
          className="p-2 rounded-lg hover:bg-secondary hover:text-secondary"
        >
          <SlArrowLeft className="w-5 h-5" />
        </button>
        <div className="grid grid-cols-7 gap-2 flex-1 mx-4">
          {currentWeek.map((date, index) => (
            <div
              key={index}
              className={`text-center cursor-pointer p-2 rounded-lg ${
                date.toDateString() === selectedDate.toDateString() 
                  ? 'bg-highlight text-highlight' 
                  : 'bg-bg hover:bg-secondary hover:text-secondary'
              }`}
              onClick={() => handleDayClick(date)}
            >
              <div className="text-sm font-semibold">{formatWeekday(date)}</div>
              <div className="text-lg">{formatDate(date)}</div>
            </div>
          ))}
        </div>
        <button 
          onClick={handleNextWeek}
          className="p-2 rounded-lg hover:bg-secondary hover:text-secondary"
        >
          <SlArrowRight className="w-5 h-5" />
        </button>
      </div>

      <div className="sm:hidden">
        <div className="flex justify-between items-center mb-4">
          <button 
            onClick={handlePrevWeek}
            className="p-2 rounded-lg hover:bg-secondary hover:text-secondary"
          >
            <SlArrowLeft className="w-5 h-5" />
          </button>
          <span className="text-lg font-semibold">
            {formatDate(currentWeek[0])} - {formatDate(currentWeek[6])}
          </span>
          <button 
            onClick={handleNextWeek}
            className="p-2 rounded-lg hover:bg-secondary hover:text-secondary"
          >
            <SlArrowRight className="w-5 h-5" />
          </button>
        </div>
        <div className="space-y-2">
          {currentWeek.map((date, index) => (
            <div
              key={index}
              className={`flex items-center justify-between p-3 rounded-lg cursor-pointer ${
                date.toDateString() === selectedDate.toDateString() 
                  ? 'bg-highlight text-highlight' 
                  : 'bg-bg hover:bg-secondary hover:text-secondary'
              }`}
              onClick={() => handleDayClick(date)}
            >
              <div className="font-semibold">{formatWeekday(date)}</div>
              <div>{formatDate(date)}</div>
            </div>
          ))}
        </div>
      </div>

      <div className="hidden sm:block mt-4">
        <CalendarDayCard date={selectedDate} />
      </div>

      {showDayCard && (
        <div className="sm:hidden fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-major rounded-lg w-full max-w-md max-h-[80vh] overflow-y-auto">
            <div className="sticky top-0 z-10 bg-major border-b border-outline px-4 py-3 flex justify-between items-center">
              <h2 className="text-lg font-medium">
                {formatWeekday(selectedDate)} {formatDate(selectedDate)}
              </h2>
              <button 
                onClick={handleCloseDayCard}
                className="p-2 hover:bg-bg/10 rounded-full"
              >
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div className="p-4">
              <CalendarDayCard date={selectedDate} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CalendarWidget;
