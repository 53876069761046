import React from 'react';

function Modal({ isOpen, onClose, children }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* 背景遮罩 */}
      <div 
        className="fixed inset-0 bg-black bg-opacity-50"
        onClick={onClose}
      ></div>
      
      {/* 模态框内容 - 使用与AssistantSettings相同的bg-bg主题 */}
      <div className="relative z-50 bg-bg rounded-lg shadow-xl max-w-lg w-full mx-4">
        {/* 关闭按钮 */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-inherit hover:text-highlight"
        >
          ✕
        </button>
        
        {children}
      </div>
    </div>
  );
}

export default Modal; 