import React, { createContext, useState, useContext, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { ENDPOINTS, getApiUrl } from '../config/apiConfig';
import { userConfig } from '../config/userConfig';

const PreferenceContext = createContext();

export const PreferenceProvider = ({ children }) => {
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'zh');
  const [avatars, setAvatars] = useState({
    user: userConfig.defaultUserAvatar,
    ai: userConfig.defaultAIAvatar
  });
  const { getAuthenticatedAxios } = useAuth();

  useEffect(() => {
    fetchPreferences();
  }, []);

  const fetchPreferences = async () => {
    try {
      const authAxios = await getAuthenticatedAxios();
      const response = await authAxios.get(getApiUrl(ENDPOINTS.USER_SETTINGS));
      
      if (response.data.language) {
        setLanguage(response.data.language);
        localStorage.setItem('language', response.data.language);
      }
      
      if (response.data.user_avatar_url || response.data.ai_avatar_url) {
        setAvatars({
          user: response.data.user_avatar_url || userConfig.defaultUserAvatar,
          ai: response.data.ai_avatar_url || userConfig.defaultAIAvatar
        });
      }
    } catch (error) {
      console.error('获取用户偏好设置失败:', error);
    }
  };

  const updatePreferences = async (updates) => {
    try {
      const authAxios = await getAuthenticatedAxios();
      const formData = new FormData();
      
      if (updates.language) {
        formData.append('language', updates.language);
      }
      if (updates.userAvatar) {
        formData.append('userAvatar', updates.userAvatar);
        console.log('Uploading user avatar:', updates.userAvatar.substring(0, 100) + '...');
      }
      if (updates.aiAvatar) {
        formData.append('aiAvatar', updates.aiAvatar);
        console.log('Uploading AI avatar:', updates.aiAvatar.substring(0, 100) + '...');
      }

      console.log('Sending update request with formData:', {
        hasLanguage: !!updates.language,
        hasUserAvatar: !!updates.userAvatar,
        hasAiAvatar: !!updates.aiAvatar
      });

      const response = await authAxios.post(
        getApiUrl(ENDPOINTS.USER_SETTINGS),
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        }
      );

      console.log('Server response:', response.data);

      if (response.data) {
        if (updates.language) {
          setLanguage(updates.language);
          localStorage.setItem('language', updates.language);
        }
        
        if (response.data.user_avatar_url || response.data.ai_avatar_url) {
          setAvatars(prev => ({
            user: response.data.user_avatar_url || prev.user,
            ai: response.data.ai_avatar_url || prev.ai
          }));
        }
      }

      return response.data;
    } catch (error) {
      console.error('更新用户偏好设置失败:', error);
      console.error('错误详情:', error.response?.data || error.message);
      throw error;
    }
  };

  return (
    <PreferenceContext.Provider value={{
      language,
      setLanguage,
      avatars,
      updatePreferences,
      fetchPreferences
    }}>
      {children}
    </PreferenceContext.Provider>
  );
};

export const usePreference = () => {
  const context = useContext(PreferenceContext);
  if (!context) {
    throw new Error('usePreference must be used within a PreferenceProvider');
  }
  return context;
}; 